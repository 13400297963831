import {
  makeObservable,
  observable,
  action,
  runInAction,
} from "mobx";

import agent from "../../agent";


export default class SignupCompleteModel {

  invitation = { name: "..." };

  isLoading = true;

  constructor() {
    makeObservable(this, {
      invitation: observable,
      isLoading: observable,
      load: action,
    });
  }

  load({ params }) {
    this.currentParams = params;
    this.isLoading = true;
    const { id, projectId } = params;
    agent.InvitationPerson.get(projectId, id)
      .then(invitation => {
        runInAction(() => {
          this.invitation = invitation.data;
          this.isLoading = false;
        });
    });
  }
}
