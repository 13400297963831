import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Panel, { PanelNoLoader } from "../../components/panel";
import Snackbar from "../../components/snackbar";

import { useGlobal } from "../../stores/global";
import { withViewModel } from "../../utils/vm";
import Model from "./edit_user_model";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  buttons: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

const Person = observer(({ VM }) => {
  useGlobal().setTitle(`Edit My User`);
  const classes = useStyles();
  return (
    <React.Fragment>
      <Panel isLoading={VM.isLoading}>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <TextField
                id="firstName"
                label="First name"
                value={VM.item.firstName}
                onChange={(e) => VM.updateField("firstName", e.target.value)}
                fullWidth
                error={VM.hasError("firstName")}
                helperText={VM.getError("firstName")}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                id="lastName"
                label="Last name"
                value={VM.item.lastName}
                onChange={(e) => VM.updateField("lastName", e.target.value)}
                fullWidth
                error={VM.hasError("lastName")}
                helperText={VM.getError("lastName")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="email"
                label="E-mail"
                type="email"
                value={VM.item.email}
                onChange={(e) => VM.updateField("email", e.target.value)}
                fullWidth
                error={VM.hasError("email")}
                helperText={VM.getError("email")}
              />
            </Grid>
            <Grid item xs={12} className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => VM.update()}
              >
                Update my Information
              </Button>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          handleClose={() => VM.hideGlobalMessage()}
          {...VM.globalMessage}
        />
      </Panel>
      {!VM.isLoading && (
        <PanelNoLoader>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <TextField
                  id="oldPassword"
                  label="Old Password"
                  type="password"
                  value={VM.oldPassword}
                  onChange={(e) =>
                    VM.updateFieldOnItem(VM, "oldPassword", e.target.value)
                  }
                  fullWidth
                  error={VM.oldPasswordError}
                  helperText={
                    VM.oldPasswordError &&
                    "Old Password is not correct"
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <TextField
                  id="newPassword"
                  label="New Password"
                  type="password"
                  value={VM.newPassword}
                  onChange={(e) =>
                    VM.updateFieldOnItem(VM, "newPassword", e.target.value)
                  }
                  fullWidth
                  error={VM.newPasswordError}
                  helperText={
                    VM.newPasswordError &&
                    "Password should contain an upper-case, lower-case and number symbol"
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <TextField
                  id="newPasswordRepeat"
                  label="Repeat New Password"
                  type="password"
                  value={VM.newPasswordRepeat}
                  onChange={(e) =>
                    VM.updateFieldOnItem(
                      VM,
                      "newPasswordRepeat",
                      e.target.value
                    )
                  }
                  fullWidth
                  error={VM.newPasswordRepeatError}
                  helperText={
                    VM.newPasswordRepeatError &&
                    "Repeated password do not match new password"
                  }
                />
              </Grid>
              <Grid item xs={12} className={classes.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => VM.updatePassword()}
                >
                  Set New Password
                </Button>
              </Grid>
            </Grid>
          </div>
        </PanelNoLoader>
      )}
    </React.Fragment>
  );
});

export default withViewModel(new Model())(Person);
