import React from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import AddIcon from '@material-ui/icons/Add';

import Fab from "../../components/fab";
import Table from "../../components/table";
import Loader from "../../components/loader";
import Search from "../../components/search"

import { makeObservable, observable, action, runInAction, computed } from "mobx";
import { observer } from "mobx-react-lite";

import { useGlobal } from "../../stores/global";
import { withViewModel } from "../../utils/vm";
import { objectKeysToString, parseDate, format } from "../../utils/helpers";
import agent from "../../agent";

class Model {
  isLoading = false;
  items = [];
  filter = {}

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      items: observable,
      filter: observable,
      setFiltered: action,
      load: action,
      filtered: computed
    });
  }

  load() {
    this.isLoading = true;
    agent.Projects.list().then((response) => {
      runInAction(() => {
        if (response) {
          this.items = response.data.map(item => {
            item.start = parseDate(item.start);
            item.end = parseDate(item.end);
            return item;
          });
        }
        this.isLoading = false;
      });
    });
  }

  setFiltered(value) {
    this.filter.search = value;
  }

  get filtered() {
    if (!this.filter.search || this.filter.search.length < 2) {
      return this.items;
    }
    const filterLowerCase = this.filter.search.toLowerCase();
    return this.items.filter(item => {
      return objectKeysToString(item, "|").indexOf(filterLowerCase) >= 0;
    })
  }
}

const model = new Model();

const columns = [
  { id: "name", label: "Name" },
  { id: "start", label: "Start", computed: (date) => format(date) },
  { id: "end", label: "End", computed: (date) => format(date)  },
];

const Overview = observer(({ VM }) => {
  const globalStore = useGlobal();
  globalStore.setTitle("Projects");

  const navigate = useNavigate();

  return (
    <Loader isLoading={VM.isLoading}>
      <Search value={VM.filter.search} handleChange={val => VM.setFiltered(val)}></Search>
      <Grid item xs={12}>
        <Table
          rows={VM.filtered}
          columns={columns}
          handleRowClick={(element) =>
            navigate(`/backend/projects/edit/${element.id}`)
          }
        ></Table>
        <Fab onClick={() => navigate("/backend/projects/new")}>
          <AddIcon />
        </Fab>
      </Grid>
    </Loader>
  );
});

export default withViewModel(model)(Overview);
