import React from "react";
import { Routes, Route } from "react-router-dom";

import List from './list'
import Person from './person'

const Routing = () => {

  return (
    <Routes>
        <Route path="/" element={<List />} />
        <Route path="edit/:id" element={<Person />} />
    </Routes>
  );
};

export default Routing;