import React from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Chip from "@material-ui/core/Chip";

import Fab from "../../components/fab";
import Table from "../../components/table";
import Loader from "../../components/loader";
import Search from "../../components/search";

import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed,
} from "mobx";
import { observer } from "mobx-react-lite";

import { useGlobal } from "../../stores/global";
import { withViewModel } from "../../utils/vm";
import agent from "../../agent";
import { objectKeysToString } from "../../utils/helpers";

class Model {
  isLoading = false;
  items = [];
  filter = {};

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      items: observable,
      filter: observable,
      setFiltered: action,
      load: action,
      filtered: computed,
    });
  }

  load() {
    this.isLoading = true;
    agent.IndustryPerson.list().then((response) => {
      runInAction(() => {
        if (response) {
          this.items = response.data.map(item => Object.assign(item, { searchString: objectKeysToString(item, "|")}));
        }
        this.isLoading = false;
      });
    });
  }

  setFiltered(value) {
    this.filter.search = value;
  }

  get filtered() {
    if (!this.filter.search || this.filter.search.length < 2) {
      return this.items;
    }
    const filterLowerCases = this.filter.search.toLowerCase().split(/ +/);
    return this.items.filter((item) => {
       return filterLowerCases.every(filterKey => item.searchString.indexOf(filterKey) >= 0);
    });
  }
}

const model = new Model();

const columns = [
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "title", label: "Title" },
  { id: "email", label: "Email" },
  { id: "mobile", label: "Cell Phone" },
  { id: "companyName", label: "Company" },
  { id: "companyCountryName", label: "Country" },
  {
    id: "tags",
    label: "Tags",
    computed: (tags, elem) => {
      return tags.map((tag, index) => {
        return (
          <Chip
            key={`${elem.id}_tag_index_${index}`}
            variant="outlined"
            size="small"
            label={tag}
            color="primary"
          />)
      })
    },
  },
];

const Overview = observer(({ VM }) => {
  const globalStore = useGlobal();
  globalStore.setTitle("People");

  const navigate = useNavigate();

  return (
    <Loader isLoading={VM.isLoading}>
      <Search
        value={VM.filter.search}
        handleChange={(val) => VM.setFiltered(val)}
      ></Search>
      <Grid item xs={12}>
        <Table
          rows={VM.filtered}
          columns={columns}
          handleRowClick={(element) =>
            navigate(`/backend/persons/edit/${element.id}`)
          }
        ></Table>
        <Fab onClick={() => navigate("/backend/persons/edit/add")}>
          <PersonAddIcon />
        </Fab>
      </Grid>
    </Loader>
  );
});

export default withViewModel(model)(Overview);
