import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import ReCAPTCHA from "react-google-recaptcha";

import Panel from "../../components/panel";
import Snackbar from "../../components/snackbar";

import { useGlobal } from "../../stores/global";
import { withViewModel } from "../../utils/vm";
import Model from "./signup_model";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  signup: {
    margin: theme.spacing(2),
  },
}));

const Person = observer(({ VM }) => {
  useGlobal().setTitle(`Signup for '${VM.invitation.name}'`);

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Panel isLoading={VM.isLoading}>
      <form className={classes.signup} noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body2">
              Please fill in all relevant information below.
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="firstName"
              label="First name"
              value={VM.item.firstName}
              onChange={(e) => VM.updateField("firstName", e.target.value)}
              onBlur={() => VM.validate()}
              fullWidth
              required
              error={VM.hasError("firstName")}
              helperText={VM.getError("firstName")}
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              id="lastName"
              label="Last name"
              value={VM.item.lastName}
              onChange={(e) => VM.updateField("lastName", e.target.value)}
              onBlur={() => VM.validate()}
              fullWidth
              required
              error={VM.hasError("lastName")}
              helperText={VM.getError("lastName")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="title"
              label="Title"
              value={VM.item.title}
              onChange={(e) => VM.updateField("title", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="email"
              label="E-mail"
              type="email"
              value={VM.item.email}
              onChange={(e) => VM.updateField("email", e.target.value)}
              fullWidth
              onBlur={() => VM.validate()}
              error={VM.hasError("email")}
              helperText={VM.getError("email")}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth
              required
              error={VM.hasError("companyCountryCode")}
            >
              <InputLabel shrink id="company-country-code-label">
                Country
              </InputLabel>
              <NativeSelect
                id="companyCountryCode"
                value={VM.item.companyCountryCode}
                onChange={(e) =>
                  VM.updateField("companyCountryCode", e.target.value)
                }
                onBlur={() => VM.validate()}
              >
                <option value="">Select a country</option>
                {VM.countries.map((country) => {
                  return (
                    <option
                      key={`key_country_${country.countryCode}`}
                      value={country.countryCode}
                    >
                      {country.name}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="mobile"
              label="Mobile phone"
              value={VM.item.mobile}
              onChange={(e) => VM.updateField("mobile", e.target.value)}
              onBlur={() => VM.validate()}
              error={VM.hasError("mobile")}
              helperText={
                "Mobile phone should start with + for the dial code and then digits for the phone number"
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Company Information</Typography>
            <TextField
              id="companyName"
              label="Company Name"
              value={VM.item.companyName}
              onChange={(e) => VM.updateField("companyName", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="companyWebsite"
              label="Website of the company"
              value={VM.item.companyWebsite}
              onChange={(e) => VM.updateField("companyWebsite", e.target.value)}
              error={VM.hasError("companyWebsite")}
              helperText={
                  "The website should be with or without www, have at least two components, and have no '/'."
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="companyCity"
              label="City of the company or branch office"
              value={VM.item.companyCity}
              onChange={(e) => VM.updateField("companyCity", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Other Settings</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Switch name="future" />}
                checked={VM.item.futureEvents}
                onChange={(e) =>
                  VM.updateField("futureEvents", e.target.checked)
                }
                disabled={VM.invitation.requireFuture}
                label="Allow invitations to future events"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
          <ReCAPTCHA
              sitekey="6Le24usZAAAAAPugBQdLfA-Rgaur-A_ynAuYXFxt"
              onChange={() => VM.setCaptcha(true)}
              onExpired={() => VM.setCaptcha(false)}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                disabled={!VM.captcha}
                onClick={() =>
                  VM.insert((projectId, id) => {
                    navigate(`/public/signup_complete/${projectId}/${id}`);
                  })
                }
              >
                Sign me up
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        handleClose={() => VM.hideGlobalMessage()}
        {...VM.globalMessage}
      />
    </Panel>
  );
});

const View = observer(({ VM }) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const globalStore = useGlobal();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {globalStore.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" classsName={classes.container}>
          <Grid container>
            <Person VM={VM} />
          </Grid>
        </Container>
      </main>
    </div>
  );
});

export default withViewModel(new Model())(View);
