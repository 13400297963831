import React, { useEffect, useRef } from "react";
import { useChartLoader } from "../stores/chart";


export const PieChart = ({ data, title, ...restProps }) => {
  let chartLoader = useChartLoader();
  let divChart = useRef(null);
  useEffect(() => {
    chartLoader.loadChart(google => {
      if (divChart.current == null) {
        return;
      }
      var dt = google.visualization.arrayToDataTable(data || []);
      var options = {
        title: title,
        backgroundColor: { fill:'transparent' },
        fontName: "Roboto",
        titleTextStyle: {
            fontSize: 16,
            fontName: "Roboto",
        }
      };
      var chart = new google.visualization.PieChart(divChart.current);
      chart.draw(dt, options);
    }, null);
  }, [divChart]);
  return <div ref={divChart} {...restProps}></div>;
};
