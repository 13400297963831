import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
  get,
} from "mobx";

import { objectKeysToString, parseDate, format } from "../../utils/helpers";
import agent from "../../agent";

class DashboardProjectsModel {

  items = []
  isLoading = false;

  constructor() {
    makeObservable(this, {
      items: observable,
      isLoading: observable,
      load: action,
    })
  }

  load() {
    this.isLoading = true;
    agent.Projects.active().then(active => {
      runInAction(() => {
        this.items = active.data.map(item => {
          item.start = parseDate(item.start);
          item.end = parseDate(item.end);
          return item;
        });
        this.isLoading = false;
      })
    })
  }
}

class DashboardPeopleYearModel {
  data = [];
  isLoading = false;

  constructor() {
    makeObservable(this, {
      data: observable,
      isLoading: observable,
      load: action,
    });
  }

  load() {
    this.isLoading = true;
    agent.Dashboard.getPeopleByYear().then((stat) => {
      runInAction(() => {
        this.data = [
          ["Year", "Count"],
          ...stat.data.map((item) => [`${item.year}`, item.count]),
        ];
        this.isLoading = false;
      });
    });
  }
}

class DashboardPeopleCountryModel {
  data = [];
  isLoading = false;

  constructor() {
    makeObservable(this, {
      data: observable,
      isLoading: observable,
      load: action,
    });
  }

  load() {
    this.isLoading = true;
    const countriesQuery = agent.Countries.list();
    const statQuery = agent.Dashboard.getPeopleByCountry();
    Promise.all([countriesQuery, statQuery]).then(([countries, stat]) => {
      runInAction(() => {
        const countryMap = new Map(countries.data.map((obj) => [obj.countryCode, obj.name]));
        this.data = [
          ["Country", "Count"],
          ...stat.data.map((item) => [
            countryMap.get(item.countryCode),
            item.count,
          ]),
        ];
        this.isLoading = false;
      });
    });
  }
}

export default class DashboardModel {
  peopleYear = new DashboardPeopleYearModel();
  peopleCountry = new DashboardPeopleCountryModel();
  activeProjects = new DashboardProjectsModel();

  constructor() {
    makeObservable(this, {
      peopleYear: observable,
      peopleCountry: observable,
      activeProjects: observable,
      load: action,
    });
  }

  load() {
    this.peopleYear.load();
    this.peopleCountry.load();
    this.activeProjects.load();
  }
}
