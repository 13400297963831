import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, Prompt } from "react-router-dom";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";

import Panel from "../../components/panel";
import Snackbar from "../../components/snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import { useGlobal } from "../../stores/global";
import { withViewModel } from "../../utils/vm";
import Model from "./person_model";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  buttons: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

const Person = observer(({ VM }) => {
  if (VM.isNew) {
    useGlobal().setTitle("Add New Person");
  } else {
    useGlobal().setTitle(`Edit ${VM.name}`);
  }
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Panel isLoading={VM.isLoading}>
      <form className={classes.root} noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <TextField
              id="firstName"
              label="First name"
              value={VM.item.firstName}
              onChange={(e) => VM.updateField("firstName", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              id="lastName"
              label="Last name"
              value={VM.item.lastName}
              onChange={(e) => VM.updateField("lastName", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="title"
              label="Title"
              value={VM.item.title}
              onChange={(e) => VM.updateField("title", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="email"
              label="E-mail"
              type="email"
              value={VM.item.email}
              onChange={(e) => VM.updateField("email", e.target.value)}
              onBlur={() => VM.validate()}
              fullWidth
              error={VM.hasError("email")}
              helperText={VM.getError("email")}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="mobile"
              label="Mobile phone"
              value={VM.item.mobile}
              onChange={(e) => VM.updateField("mobile", e.target.value)}
              onBlur={() => VM.validate()}
              error={VM.hasError("mobile")}
              helperText={
                "Mobile phone should start with + and only digits afterwards"
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Company Information</Typography>
            <TextField
              id="companyName"
              label="Company Name"
              value={VM.item.companyName}
              onChange={(e) => VM.updateField("companyName", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="companyWebsite"
              label="Company Website"
              value={VM.item.companyWebsite}
              onChange={(e) => VM.updateField("companyWebsite", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="companyCity"
              label="City of the company or branch office"
              value={VM.item.companyCity}
              onChange={(e) => VM.updateField("companyCity", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel shrink id="company-country-code-label">
                Country of the company or branch office
              </InputLabel>
              <NativeSelect
                id="companyCountryCode"
                value={VM.item.companyCountryCode}
                onChange={(e) =>
                  VM.updateField("companyCountryCode", e.target.value)
                }
                className={classes.selectDial}
              >
                <option value="">Select a country</option>
                {VM.countries.map((country) => {
                  return (
                    <option
                      key={`key_country_${country.countryCode}`}
                      value={country.countryCode}
                    >
                      {country.name}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Person Settings</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Switch name="future" />}
                checked={VM.item.futureEvents}
                onChange={(e) =>
                  VM.updateField("futureEvents", e.target.checked)
                }
                label="Allow invitations to future events"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<Switch name="unstrusted" />}
                checked={VM.item.untrusted}
                onChange={(e) =>
                  VM.updateField("untrusted", e.target.checked)
                }
                label="Possible falsy data or bad behavior"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Tags</Typography>
            {VM.item.tags.map((tag, index) => {
              return (
                <Chip
                  key={`tag_index_${index}`}
                  variant="outlined"
                  size="small"
                  label={tag}
                  onDelete={() => VM.deleteTag(tag)}
                  color="primary"
                />
              );
            })}
            <Button color="primary" onClick={() => VM.openTagDialog()}>
              New Tag
            </Button>
          </Grid>
          {VM.hasExisting && (
            <Grid item xs={12}>
              <SnackbarContent
                message="This looks like an existing user based on the data entered so far - input more data or select an option"
                action={
                  <Fragment>
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => VM.clearExisting()}
                    >
                      Close warning
                    </Button>
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        navigate(`/backend/persons/edit/${VM.existing.id}`);
                        VM.loadId(VM.existing.id);
                      }}
                    >
                      Go to existing
                    </Button>
                  </Fragment>
                }
                className={classes.snackbar}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <div className={classes.buttons}>
              {VM.isNew && (
                <Button
                  variant="contained"
                  disabled={VM.hasExisting}
                  color="primary"
                  onClick={() =>
                    VM.insert((id) => {
                      navigate(`/backend/persons/edit/${id}`);
                      VM.setGlobalMessage(
                        `${VM.name} was created successfully`,
                        "info"
                      );
                    })
                  }
                >
                  Add person
                </Button>
              )}
              {!VM.isNew && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={VM.hasExisting}
                  onClick={() => {
                    VM.update(() => {
                      VM.setGlobalMessage(
                        `${VM.name} was updated successfully`
                      );
                    });
                  }}
                >
                  Update person
                </Button>
              )}
              {!VM.isNew && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => VM.loadId("add")}
                >
                  New Person
                </Button>
              )}
              {!VM.isNew && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => VM.openDeleteDialog()}
                >
                  Delete person
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  VM.clear();
                  navigate("/backend/persons");
                }}
              >
                Back
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        handleClose={() => VM.hideGlobalMessage()}
        {...VM.globalMessage}
      />
      <Dialog
        open={VM.tagDialogOpen}
        onClose={() => VM.closeTagDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add New Tag</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            Write the new tag below. You can use ',' to add multiple tags.
          </DialogContentText>
          <TextField
            autoFocus
            required
            id="newTag"
            label="Tag"
            value={VM.item.newTag}
            onChange={(e) => VM.setTag(e.target.value)}
            fullWidth
            error={VM.newTagError}
            helperText={VM.newTagError && "Tag cannot be empty"}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => VM.closeTagDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => VM.closeTagDialog(true)} color="primary">
            Add Tag
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={VM.deleteDialogOpen}
        onClose={() => VM.closeDeleteDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete {`${VM.item.firstName} ${VM.item.lastName}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            Are you sure you want to delete {`${VM.item.firstName} ${VM.item.lastName}`}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => VM.closeDeleteDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => VM.closeDeleteDialog(true, () => navigate("/backend/persons"))} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Prompt
      when={VM.isNew && VM.item.name !== "" && VM.item.email !== ""}
      message='You have unsaved changes, are you sure you want to leave?'
    />
    </Panel>
  );
});

export default withViewModel(new Model())(Person);
