import React, { useState } from "react";
import { makeObservable, observable, action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(sortDirection, orderBy) {
  return sortDirection === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getHeaderCell(column, orderBy, sortDirection, handleSort) {
  const { id, numeric, type = "text", label } = column;
  if (column.type == "checkbox") {
    const { selectedCount, count, selectDeselectAll } = column;
    return (
      <TableCell padding="checkbox" key={`head_${id}`}>
        <Checkbox
          indeterminate={selectedCount > 0 && selectedCount < count}
          checked={count > 0 && selectedCount === count}
          onChange={(evt) => selectDeselectAll(evt.target.checked)}
          inputProps={{ "aria-label": "select all" }}
        />
      </TableCell>
    );
  } else {
    return (
      <TableCell
        key={`head_${id}`}
        align={numeric ? "right" : "left"}
        sortDirection={orderBy == id ? sortDirection : false}
      >
        <TableSortLabel
          active={orderBy === id}
          direction={orderBy === id ? sortDirection : "asc"}
          onClick={() => handleSort(column)}
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  }
}

function getRowCell(column, row, rowIndex) {
  const {
    type = "text",
    computed,
    selected,
    handleChange,
  } = column;
  var content;
  var padding = column.padding || "default";
  if (type == "checkbox") {
    padding = "checkbox";
    content = (
      <Checkbox checked={selected(row)} onChange={() => handleChange(row)} />
    );
  } else {
    content = column.computed
      ? column.computed(row[column.id], row)
      : row[column.id];
  }
  return (
    <TableCell
      key={`key_cell_${rowIndex}_${column.id}`}
      align={column.numeric ? "right" : "left"}
      padding={padding}
    >
      {content}
    </TableCell>
  );
}

export default function MyTable({
  columns,
  rows,
  handleRowClick,
  stickyHeader = false,
}) {
  const [sortDirection, setSortDirection] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  
  const handleSort = (column) => {
    const newSortDirection =
      orderBy == column.id ? (sortDirection == "asc" ? "desc" : "asc") : "asc";
    setSortDirection(newSortDirection);
    setOrderBy(column.id);
  };

  const comparator = getComparator(sortDirection, orderBy);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Table" stickyHeader={stickyHeader}>
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              return getHeaderCell(column, orderBy, sortDirection, handleSort);
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(rows, comparator).map((row, rowIndex) => (
            <TableRow
              key={`key_row_${rowIndex}`}
              hover
              role="checkbox"
              onClick={() => {
                if (handleRowClick) {
                  handleRowClick(row);
                }
              }}
              tabIndex={-1}
            >
              {columns.map((column) => getRowCell(column, row, rowIndex))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
