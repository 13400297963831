import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";

import axios from "axios";
import agent from "../../agent";

class EditProjectFilesModel {
  mainVM;
  params;

  artwork = [];
  documentation = [];

  documentationUploading = [];

  constructor() {
    makeObservable(this, {
      artwork: observable,
      documentation: observable,
      documentationUploading: observable,
      init: action,
      uploadFile: action,
      delete: action,
    });
  }

  init(mainVM, params) {
    this.mainVM = mainVM;
    this.params = params;
    const docPromise = agent.ProjectFiles.list(params.id, 0);
    const artWorksPromise = agent.ProjectFiles.list(params.id, 1);
    return Promise.all([docPromise, artWorksPromise])
      .then(([docs, artWorks]) => {
        if (!artWorks || !docs) return;
        runInAction(() => {
          this.artwork = artWorks.data;
          this.documentation = docs.data;
        });
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not get links for project: " + error.message,
          "error"
        );
      });
  }

  async uploadFile(file, type) {
    const { id } = this.params;
    try {
      const fileUploadResponse = await agent.FileUpload.getForUpload({
        projectId: id,
        fileName: file.name,
        contentType: file.type,
      });
      var options = {
        headers: {
          "Content-Type": file.type,
        },
      };
      const { key, url } = fileUploadResponse.data;
      const uploadResult = await axios.put(url, file, options);
      const newFile = await agent.ProjectFiles.insert(id, {
        key: key,
        fileName: file.name,
        type: type,
      });
      runInAction(() => {
        if (type == 0) {
          this.documentation = [...this.documentation, newFile.data];
        } else {
          this.artwork = [...this.artwork, newFile.data];
        }
      });
    } catch (error) {
      this.mainVM.setGlobalMessage(
        `Could not upload file ${file.name}: ` + error.message,
        "error"
      );
    }
  }

  delete(item) {
    agent.ProjectFiles.delete(this.params.id, item.id)
      .then((response) => {
        if (item.type == 0) {
          const index = this.documentation.indexOf(item);
          this.documentation = [
            ...this.documentation.slice(0, index),
            ...this.documentation.slice(index + 1),
          ];
        } else {
          const index = this.artwork.indexOf(item);
          this.artwork = [
            ...this.artwork.slice(0, index),
            ...this.artwork.slice(index + 1),
          ];
        }
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not delete file for project: " + error.message,
          "error"
        );
      });
  }
}

export default EditProjectFilesModel;
