import React from "react";

import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

import MuiAlert from '@material-ui/lab/Alert';

import Button from "@material-ui/core/Button";
import Panel from "../../components/panel";
import Grid from "@material-ui/core/Grid";
import Snackbar from "../../components/snackbar";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { useGlobal } from "../../stores/global";
import { withViewModel } from "../../utils/vm";
import ProjectSendMailModel from "./project_send_mail_model";
import NewLineElement from "../../components/newlineelement";

import { useTemplates } from "../../stores/templates";

import Table from "../../components/table";

import Editor from "../../components/tinymce";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    "& > *": {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  },
}));

const columns = (VM, selectedCount, count) => [
  {
    id: "id",
    label: "",
    type: "checkbox",
    selectedCount: selectedCount,
    count: count,
    selectDeselectAll: (val) => VM.selectDeselectAll(val),
    selected: (row) => row.selected,
    handleChange: (item) => VM.changeSelection(item),
  },
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "email", label: "Email" },
];

const ProjectSendMail = observer(({ VM }) => {
  const globalStore = useGlobal();
  globalStore.setTitle("Send mail to customers");

  const classes = useStyles();
  const navigate = useNavigate();
  const templates = useTemplates();

  return (
    <React.Fragment>
      <Panel isLoading={VM.isLoading}>
        <div className={classes.root}>
          <Grid item xs={12}>
            <Select
              id="template"
              label="Template"
              value={VM.selectedTemplateId}
              onChange={(e) => VM.setContentTemplate(e.target.value)}
              fullWidth
            >
              {VM.items.map((template) => {
                return <MenuItem value={template.id}>{template.name}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={12} className={classes.mt2}>
            <TextField
              id="tag"
              label="Tag"
              value={VM.currentTemplate.tag}
              onChange={(e) => VM.updateField("tag", e.target.value)}
              fullWidth
              error={VM.hasError("tag")}
              helperText={VM.getError("tag")}
            />
          </Grid>
          <Grid item xs={12} className={classes.mt2}>
            <TextField
              id="sender"
              label="Sender"
              value={VM.currentTemplate.sender}
              onChange={(e) => VM.updateField("sender", e.target.value)}
              fullWidth
              error={VM.hasError("sender")}
              helperText={VM.getError("sender")}
            />
          </Grid>
          <Grid item xs={12} className={classes.mt2}>
            <TextField
              id="title"
              label="Title"
              value={VM.currentTemplate.title}
              onChange={(e) => VM.updateField("title", e.target.value)}
              fullWidth
              error={VM.hasError("title")}
              helperText={VM.getError("title")}
            />
          </Grid>
          <Grid item xs={12} className={classes.mt2}>
            <Editor
              value={VM.currentTemplate.content}
              templateVariables={templates.templateVariables}
              onChange={(content) => {
                VM.updateField("content", content);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                disabled={VM.sending}
                onClick={() => {
                  VM.send();
                }}
              >
                Send email
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  navigate(`/backend/projects/edit/${VM.projectId}`);
                }}
              >
                Back
              </Button>
            </div>
          </Grid>
        </div>
      </Panel>

      <Panel isLoading={VM.isLoading}>
        <div className={classes.root}>
          <Table
            stickyHeader={true}
            columns={columns(VM, VM.people.length, VM.selectedCount)}
            rows={VM.people}
          />
        </div>
      </Panel>

      <Snackbar
        handleClose={() => VM.hideGlobalMessage()}
        {...VM.globalMessage}
      />

      <Snackbar open={VM.sendResult == 1} 
        duration={6000} 
        onClose={() => navigate(`/backend/projects/edit/${VM.projectId}`)}
        severity="success"
        action={
          <Button color="inherit" size="small">
            Back
          </Button>
        }
        message={VM.sendSuccessMessage}>
      </Snackbar>

      <Snackbar
        duration={-1} 
        open={VM.sendResult == 2}
        severity="error"
        message={
          <NewLineElement text={VM.sendFailureMessage}></NewLineElement>
        }>
      </Snackbar>

    </React.Fragment>
  );
});

export default withViewModel(new ProjectSendMailModel())(ProjectSendMail);
