import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));


export default function FloatingActionButton(props) {
    const classes = useStyles();
    return (
        <Fab color="primary" className={classes.fab} onClick={props.onClick}>
            {props.children}
        </Fab>
    )
}