import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";

import { withIsLoading, withGlobalMessage, withForm } from "../../utils/vm";
import Joi from "joi";
import agent from "../../agent";
import { checkPassword } from "../../utils/helpers"

const schema = Joi.object({
  firstName: Joi.string().required().max(45),
  lastName: Joi.string().required().max(45),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
});

class EditUserModel {
  item = { firstName: "", lastName: "", email: "" };
  oldPassword = "";
  newPassword = "";
  newPasswordRepeat = "";

  oldPasswordError = false;
  newPasswordError = "";
  newPasswordRepeatError = false;

  constructor() {
    makeObservable(this, {
      item: observable,
      oldPassword: observable,
      newPassword: observable,
      newPasswordRepeat: observable,
      oldPasswordError: observable,
      newPasswordError: observable,
      newPasswordRepeatError: observable,
      load: action,
      update: action,
      updatePassword: action,
    });
  }

  load() {
    agent.Users.getCurrentUser()
      .then((response) => {
        if (!response) return;
        runInAction(() => {
          this.item = response.data;
        });
      })
      .catch((error) => {
        this.setGlobalMessage(
          "Could not get information about the user: " + error.message,
          "error"
        );
      });
  }

  update() {
    if (!this.validate()) {
      this.setGlobalMessage(
        "One or more errors - please correct the information and submit again",
        "error"
      );
    }
    agent.Users.update(this.item)
        .then(response => {
            if (!response) return;
            this.setGlobalMessage(
                "Updated your information",
                "info"
              );
        })
        .catch(error => {
            this.setGlobalMessage(
                "Could not update your information: " + error,
                "error"
              );
        });
  }

  updatePassword() {
    this.newPasswordError = false;
    this.newPasswordRepeatError = false;
    this.oldPasswordError = false;

    if (!checkPassword(this.newPassword)) {
      this.newPasswordError = true;
      return;
    }
    if (this.newPassword != this.newPasswordRepeat) {
      this.newPasswordRepeatError = true;
      return;
    }
    agent.Users.updatePassword({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
    })
      .then((response) => {
        if (!response) return;
        this.setGlobalMessage("Password updated", "info");
        runInAction(() => {
            this.oldPassword = "";
            this.newPassword = "";
            this.newPasswordRepeat = "";
        })
      })
      .catch((error) => {
        runInAction(() => {
          this.oldPasswordError = true;
          this.setGlobalMessage("Could not update password", "error");
        });
      });
  }
}

export default withIsLoading(
  withGlobalMessage(withForm(EditUserModel, schema))
);
