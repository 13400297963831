import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";

import Joi from "joi";

import agent from "../../agent";

const schema = Joi.object({
  name: Joi.string().required(),
  start: Joi.date().required(),
  end: Joi.date().required(),
});

class EditProjectLinksModel {
  mainVM;
  params;

  newLink = "";

  items = [];

  constructor() {
    makeObservable(this, {
      items: observable,
      newLink: observable,
      init: action,
      insert: action,
      delete: action,
      updateNewLink: action,
      sorted: computed,
    });
  }

  get sorted() {
    return this.items.sort((a, b) => a.created <= b.created);
  }

  init(mainVM, params) {
    this.mainVM = mainVM;
    this.params = params;
    return agent.ProjectLinks.list(params.id)
      .then((response) => {
        if (!response) return;
        runInAction(() => {
          this.items = response.data;
        });
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not get links for project: " + error.message,
          "error"
        );
      });
  }

  updateNewLink(newValue) {
    this.newLink = newValue;
  }

  insert() {
    if (this.newLink.length <= 0) {
      return;
    }
    agent.ProjectLinks.insert(this.params.id, { link: this.newLink })
      .then((response) => {
        if (!response) return;
        runInAction(() => {
          this.items = [...this.items, response.data];
          this.newLink = "";
        });
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not insert link for project: " + error.message,
          "error"
        );
      });
  }

  delete(item) {
    agent.ProjectLinks.delete(this.params.id, item.id)
      .then((response) => {
        const index = this.items.indexOf(item);
        runInAction(() => {
          this.items = [
            ...this.items.slice(0, index),
            ...this.items.slice(index + 1),
          ];
        })
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not delete link for project: " + error.message,
          "error"
        );
      });
  }
}

export default EditProjectLinksModel;
