import React from "react";
import { Routes, Route } from "react-router-dom";

import List from './list'
import NewProject from './new_project'
import EditProject from './edit_project'
import ProjectSendMail from './project_send_mail'

const Routing = () => {

  return (
    <Routes>
        <Route path="/" element={<List />} />
        <Route path="new" element={<NewProject />} />
        <Route path="edit/:id/mail" element={<ProjectSendMail />} />
        <Route path="edit/:id" element={<EditProject />} />
    </Routes>
  );
};

export default Routing;