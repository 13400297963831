import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import DescriptionIcon from "@material-ui/icons/Description";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import DropZone from "../../components/dropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  part: {
    marginTop: theme.spacing(4),
  },
}));

const EditProjectFilePart = observer(({ VM, type }) => {
  const classes = useStyles();
  const items = type == 0 ? VM.documentation : VM.artwork;

  return (
    <React.Fragment>
      <Typography variant="subtitle1">
        {type == 0 ? "Documentation" : "Artwork"}
      </Typography>
      <List className={classes.root}>
        {items.map((file) => {
          return (
            <ListItem key={`file_${file.id}`} alignItems="flex-start">
              <ListItemIcon>
                {type == 0 ? <DescriptionIcon /> : <PhotoCameraIcon />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link href={file.url} target="_blank" rel="noreferrer" variant="body2">
                    {file.fileName}
                  </Link>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => VM.delete(file)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        <Divider />
      </List>
      <DropZone
        filesHandler={(files) => files.map((file) => VM.uploadFile(file, type))}
      />
    </React.Fragment>
  );
});

const EditProjectFiles = observer(({ VM }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <EditProjectFilePart VM={VM} type={0} />
      </Grid>
      <Grid item xs={12} className={classes.part}>
        <EditProjectFilePart VM={VM} type={1} />
      </Grid>
    </div>
  );
});

export default EditProjectFiles;
