import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGlobal } from "../stores/global";

import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";

export function withClearable(VM, emptyItem, propertyName = "item") {
  return class extends VM {
    constructor(...args) {
      super(...args);
      makeObservable(this, {
        clear: action,
      });
    }

    clear() {
      this[propertyName] = Object.assign({}, emptyItem);
      this.errors = {};
      if (typeof this.hideGlobalMessage === "function") {
        this.hideGlobalMessage();
      }
    }
  };
}

export function withIsLoading(VM) {
  return class extends VM {
    
    isLoading = false;

    constructor(...args) {
      super(...args);
      makeObservable(this, {
        isLoading: observable,
        setIsLoading: action,
      });
    }

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    }
  };
}

export function withGlobalMessage(VM) {
  return class extends VM {
    globalMessage = {
      open: false,
      message: "",
      severity: "info",
    };

    constructor(...args) {
      super(...args);
      makeObservable(this, {
        globalMessage: observable,
        setGlobalMessage: action,
        hideGlobalMessage: action,
      });
    }

    setGlobalMessage(message, severity, callback) {
      this.globalMessage = {
        open: true,
        message,
        severity,
        callback,
      };
    }

    hideGlobalMessage() {
      this.globalMessage.open = false;
      if (this.globalMessage.callback) {
        this.globalMessage.callback();
      }
      this.globalMessage.callback = undefined;
    }
  };
}

export function withForm(VM, joiSchema, itemField = "item") {

  return class extends VM {

    errors = {};

    constructor(...args) {
      super(...args);
      makeObservable(this, {
        errors: observable,
        updateField: action,
        updateFieldOnItem: action,
        validate: action,
      });
    }

    hasError(field) {
      return !(this.getError(field) === undefined);
    }
  
    getError(field) {
      if (!this.errors.details) {
        return undefined;
      }
      const err = this.errors.details.find((err) => err.path.includes(field));
      if (!err) {
        return undefined;
      }
      return err.message;
    }

    updateField(field, value) {
      this.updateFieldOnItem(this[itemField], field, value);
    }

    updateFieldOnItem(item, field, value) {
      item[field] = value;
      if (!this.errors.details) {
        return;
      }
      this.errors.details = this.errors.details.filter(
        (err) => !err.path.includes(field)
      );
      if (typeof this.hideGlobalMessage === "function") {
        this.hideGlobalMessage();
      }
    }

    validate() {
      const validationResult = joiSchema.validate(this[itemField], {
        abortEarly: false,
        allowUnknown: true,
        errors: {
          label: false,
        },
      });
      this.errors = validationResult.error || {};
      return this.errors.details === undefined;
    }      
  }
}

export function withViewModel(model, config = {}) {
  window.festivall = {};

  if (window.festivall.VM == null) {
    window.festivall.VM = {};
  }

  const { persist } = config;
  const thisVM = persist ? window.festivall.VM[persist] || model : model;

  return (WrappedComponent) => (props) => {
    const params = useParams();
    const globalStore = useGlobal();

    useEffect(() => {
      if (thisVM.load) {
        thisVM.load({ params, globalStore });
      }
    }, []);

    return <WrappedComponent VM={thisVM} {...props} />;
  };
}
