import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";
import { withForm, withClearable, withGlobalMessage } from "../../utils/vm";
import Joi from "joi";

import agent from "../../agent";

const schema = Joi.object({
  name: Joi.string().required(),
  start: Joi.date().required(),
  end: Joi.date().required(),
});

const emptyItem = {
  name: "",
  start: new Date(),
  end: new Date(),
};
class NewProjectModel {
  item = Object.assign({}, emptyItem);

  constructor() {
    makeObservable(this, {
      item: observable,
      load: action,
      clear: action,
      insert: action,
    });
  }

  load() {
    this.clear();
  }

  insert(cb) {
    if (!this.validate()) {
      this.setGlobalMessage(
        "One or more errors in the input. Correct the errors and try again.",
        "error"
      );
      return;
    }
    agent.Projects.insert(this.item)
      .then((response) => {
        if (!response) return;
        cb(response.data.id);
      })
      .catch((error) => {
        runInAction(() => {
          this.setGlobalMessage(
            "Could not insert project: " + error.message,
            "error"
          );
        });
      });
  }
}

export default withForm(
  withGlobalMessage(withClearable(NewProjectModel, emptyItem)),
  schema
);
