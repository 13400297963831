import axios from "axios";

const instance = axios.create({
  baseURL: "https://crm.mxd.dk/api",
});

const errorHandler = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error(error.response.data);
    console.error(error.response.status);
    console.error(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error("Error", error.message);
  }
  console.error(error.config);
  return Promise.reject(error);
};

instance.interceptors.response.use((response) => response, errorHandler);

const requests = {
  del: (url) => instance.delete(url),
  get: (url) => instance.get(url),
  put: (url, body) => instance.put(url, body),
  post: (url, body) => instance.post(url, body),
};

const Countries = {
  list: () => requests.get("countries"),
};

const IndustryPerson = {
  list: () => requests.get("persons"),
  get: (id) => requests.get(`persons/${id}`),
  insert: (dto) => requests.post("persons", dto),
  update: (id, dto) => requests.put(`persons/${id}`, dto),
  companyInfo: () => requests.get("persons/companyinfo"),
  search: (filter) => requests.get(`persons?filter=${filter}`),
  existing: (dto) => requests.put(`persons/existing`, dto),
  delete: (id) => requests.del(`persons/${id}`),
};

const Projects = {
  list: () => requests.get("projects"),
  get: (id) => requests.get(`projects/${id}`),
  insert: (dto) => requests.post("projects", dto),
  update: (id, dto) => requests.put(`projects/${id}`, dto),
  active: () => requests.get("projects/active")
};

const ProjectLinks = {
  list: (projectId) => requests.get(`projects/${projectId}/links`),
  insert: (projectId, dto) => requests.post(`projects/${projectId}/links`, dto),
  delete: (projectId, id) => requests.del(`projects/${projectId}/links/${id}`),
};

const ProjectFiles = {
  list: (projectId, type) =>
    requests.get(`projects/${projectId}/files?type=${type}`),
  insert: (projectId, dto) => requests.post(`projects/${projectId}/files`, dto),
  delete: (projectId, id) => requests.del(`projects/${projectId}/files/${id}`),
};

const ProjectGenericInvitation = {
  get: (projectId, id) =>
    requests.get(`projects/${projectId}/invitations/${id}`),
  list: (projectId) => requests.get(`projects/${projectId}/invitations`),
  insert: (projectId, dto) =>
    requests.post(`projects/${projectId}/invitations`, dto),
  update: (projectId, id, dto) =>
    requests.put(`projects/${projectId}/invitations/${id}`, dto),
  delete: (projectId, id) =>
    requests.del(`projects/${projectId}/invitations/${id}`),
};

const ProjectMails = {
  list: (projectId) => requests.get(`projects/${projectId}/mails`),
  insert: (projectId, dto) => requests.post(`projects/${projectId}/mails`, dto),
  update: (projectId, id, dto) =>
    requests.post(`projects/${projectId}/mails/${id}`, dto),
  delete: (projectId, id) => requests.del(`projects/${projectId}/mails/${id}`),
};

const ProjectPeople = {
  list: (projectId) => requests.get(`projects/${projectId}/people`),
  addPersons: (projectId, dto) =>
    requests.put(`projects/${projectId}/people`, dto),
  updatePriority: (projectId, projectPersonId, dto) =>
    requests.put(`projects/${projectId}/people/${projectPersonId}`, dto),
  accept: (projectId, dto) =>
    requests.post(`projects/${projectId}/people/accept`, dto),
  unaccept: (projectId, dto) =>
    requests.post(`projects/${projectId}/people/unaccept`, dto),
};

const ProjectPeopleMail = {
  sendMail: (projectId, dto) => requests.post(`projects/${projectId}/people/mails`, dto),
};

const FileUpload = {
  getForUpload: (dto) => requests.post("files/upload", dto),
};

const Users = {
  getCurrentUser: () => requests.get("users/me"),
  update: (dto) => requests.put("users/me", dto),
  updatePassword: (dto) => requests.put("users/me/password", dto),
};

const InvitationPerson = {
  get: (projectId, id) => requests.get(`invitation_persons/${projectId}/${id}`),
  insert: (projectId, invitationId, dto) =>
    requests.post(`invitation_persons/${projectId}/${invitationId}`, dto),
};

const ConfirmPerson = {
  get: (projectId, id) => requests.get(`projects_public/${projectId}/confirm/${id}`),
  update: (projectId, id, dto) => requests.post(`projects_public/${projectId}/confirm/${id}`, dto),
}

const TemplateVariables = {
  get: () => requests.get(`template_variables`),
}

const Dashboard = {
  getPeopleByYear: () => requests.get('dashboard/people/year'),
  getPeopleByCountry: () => requests.get('dashboard/people/country')
}

export default {
  Countries,
  IndustryPerson,
  Projects,
  ProjectLinks,
  ProjectFiles,
  ProjectGenericInvitation,
  ProjectMails,
  ProjectPeople,
  ProjectPeopleMail,
  FileUpload,
  Users,
  InvitationPerson,
  ConfirmPerson,
  TemplateVariables,
  Dashboard,
};
