import React from "react";
import { makeObservable, action, observable, runInAction } from "mobx";

import agent from "../agent";

class TemplateStore {

    templateVariables = []

    constructor() {
        makeObservable(this, {
            templateVariables: observable,
            load: action
        });
    }

    load() {
      agent.TemplateVariables.get()
        .then(response => {
          if (!response) return;
          runInAction(() => {
            this.templateVariables = response.data;
          })
        })
    }
}


const TemplateContext = React.createContext();
 
export const TemplateProvider = ({ children }) => {
  const store = new TemplateStore();
  store.load();
  return (
    <TemplateContext.Provider value={store}>{children}</TemplateContext.Provider>
  );
};
 
/* Hook to use store in any functional component */
export const useTemplates = () => React.useContext(TemplateContext);
 
/* HOC to inject store to any functional or class component */
export const withTemplates = (Component) => (props) => {
  return <Component {...props} store={useTemplates()} />;
};
