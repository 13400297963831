import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { lighten, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "../../components/table";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import GroupAddIcon from "@material-ui/icons/GroupAdd";

import { useGlobal } from "../../stores/global";
import { buildPayload } from "./project_send_mail_model";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  mr1: {
    marginRight: theme.spacing(1),
  },
}));

const columns = (VM, type, selectedCount, count) => [
  {
    id: "id",
    label: "",
    type: "checkbox",
    selectedCount: selectedCount,
    count: count,
    selectDeselectAll: (val) => VM.selectDeselectAll(type, val),
    selected: (row) => row.selectedInPriorityTable,
    handleChange: (item) => VM.changeSelection(type, item),
  },
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "companyName", label: "Company" },
  {
    id: "priority",
    label: "Priority",
    computed: (val, elem) => {
      return (
        <React.Fragment>
          {elem.genericLink != "" && (
            <Chip
              key={`chip_genericLink_elem_${elem.id}`}
              variant="outlined"
              color="secondary"
              size="small"
              label={VM.genericInvitationsDict[elem.genericLink].name}
            />
          )}
          {elem.priority == 1 && (
            <Chip variant="outlined" color="primary" size="small" label="A" />
          )}
          {elem.priority == 2 && (
            <Chip variant="outlined" color="primary" size="small" label="B" />
          )}
          {elem.priority == 3 && (
            <Chip variant="outlined" color="primary" size="small" label="C" />
          )}
        </React.Fragment>
      );
    },
  },
  {
    id: "tags",
    label: "Tags",
    computed: (val, elem) => {
      return (
        <React.Fragment>
          {elem.customTags.map((tag) => {
            return (
              <Chip
                key={`chip_${tag}`}
                variant="outlined"
                color="secondary"
                size="small"
                label={tag}
              />
            );
          })}
        </React.Fragment>
      );
    },
  },
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = observer(
  ({ title, selectedCount, handleAcceptClick, handleMailClick }) => {
    const classes = useToolbarStyles();
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: selectedCount > 0,
        })}
      >
        {selectedCount > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {selectedCount} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
        )}

        {selectedCount > 0 && (
          <React.Fragment>
            <Tooltip title="Accept by MXD">
              <IconButton aria-label="Accept" onClick={handleAcceptClick}>
                <GroupAddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Send Mail">
              <IconButton aria-label="Send" onClick={handleMailClick}>
                <MailIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        )}
      </Toolbar>
    );
  }
);

const EditProjectNetWorld = observer(({ VM }) => {
  const navigate = useNavigate();
  const globalStore = useGlobal();
  const myColumns = columns(
    VM,
    1,
    VM.worldPrioritySelectedCount,
    VM.worldPriority.length
  );
  return (
    <React.Fragment>
      <EnhancedTableToolbar
        title="International List"
        VM={VM}
        selectedCount={VM.worldPrioritySelectedCount}
        handleAcceptClick={() => VM.acceptSelected(1)}
        handleMailClick={() => {
          buildPayload(globalStore, VM, VM.worldPrioritySelected, VM.worldPriority);
          navigate(`/backend/projects/edit/${VM.mainVM.projectId()}/mail`);
        }}
      />
      <Table stickyHeader={true} columns={myColumns} rows={VM.worldPriority} />
    </React.Fragment>
  );
});

const EditProjectNetNational = observer(({ VM }) => {
  const navigate = useNavigate();
  const globalStore = useGlobal();
  const myColumns = columns(
    VM,
    0,
    VM.nationalPrioritySelectedCount,
    VM.nationalPriority.length
  );
  return (
    <React.Fragment>
      <EnhancedTableToolbar
        title="National List"
        VM={VM}
        selectedCount={VM.nationalPrioritySelectedCount}
        handleAcceptClick={() => VM.acceptSelected(0)}
        handleMailClick={() => {
          buildPayload(globalStore, VM, VM.nationalPrioritySelected, VM.nationalPriority);
          navigate(`/backend/projects/edit/${VM.mainVM.projectId()}/mail`);
        }}
      />
      <Table
        stickyHeader={true}
        columns={myColumns}
        rows={VM.nationalPriority}
      />
    </React.Fragment>
  );
});

const EditProjectNetNordic = observer(({ VM }) => {
  const navigate = useNavigate();
  const globalStore = useGlobal();
  const myColumns = columns(
    VM,
    2,
    VM.nordicPrioritySelectedCount,
    VM.nordicPriority.length
  );
  return (
    <React.Fragment>
      <EnhancedTableToolbar
        title="Nordic List (excl. DK)"
        VM={VM}
        selectedCount={VM.nordicPrioritySelectedCount}
        handleAcceptClick={() => VM.acceptSelected(2)}
        handleMailClick={() => {
          buildPayload(globalStore, VM, VM.nordicPrioritySelected, VM.nordicPriority);
          navigate(`/backend/projects/edit/${VM.mainVM.projectId()}/mail`);
        }}
      />
      <Table stickyHeader={true} columns={myColumns} rows={VM.nordicPriority} />
    </React.Fragment>
  );
});

const EditProjectNet = observer(({ VM }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid item xs={12} className={classes.mt2}>
        <EditProjectNetWorld VM={VM} />
      </Grid>
      <Grid item xs={12} className={classes.mt2}>
        <EditProjectNetNational VM={VM} />
      </Grid>
      <Grid item xs={12} className={classes.mt2}>
        <EditProjectNetNordic VM={VM} />
      </Grid>
    </div>
  );
});

export default EditProjectNet;
