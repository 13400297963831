import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";

import { withGlobalMessage } from "../../utils/vm";

class EditProjectModel {
  // vms holds a reference to all sub-vms
  vms = [];

  tabIndex = 0;

  peopleStepIndex = 0;

  isLoading = true;

  params;

  constructor(vms = []) {
    this.vms = vms;
    makeObservable(this, {
      tabIndex: observable,
      peopleStepIndex: observable,
      isLoading: observable,
      setTabIndex: action,
      setPeopleStepIndex: action,
      load: action,
    });
  }

  load({ params }) {
    this.params = params;
    const urlParams = new URLSearchParams(window.location.search);
    const newParam = urlParams.get("new");
    if (newParam) {
      this.setGlobalMessage("The project was created succesfully", "info");
    }
    this.isLoading = true;
    Promise.all(this.vms.map(vm => vm.init(this, params))).then(
      () => runInAction(() => this.isLoading = false)
    );
  }

  setTabIndex(index) {
    this.tabIndex = index;
  }

  setPeopleStepIndex(index) {
    this.peopleStepIndex = index;
  }

  projectId() {
    return this.params.id;
  }
}

export default withGlobalMessage(EditProjectModel);