import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
  get,
} from "mobx";
import Joi from "joi";

import agent from "../../agent";

import { withIsLoading, withGlobalMessage } from "../../utils/vm";

const schema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  companyCountryCode: Joi.string().required(),
  companyWebsite: Joi.string().domain().required().allow(''),
  mobile: Joi.string()
    .pattern(/^\+[0-9]+\d+$/)
    .required(),
});

class SignupModel {
  item = {
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    dial_code: "",
    mobile: "",
    companyName: "",
    companyWebsite: "",
    companyCity: "",
    companyCountryCode: "",
    futureEvents: false,
  };

  invitation = { name: "...", requireFuture: false };

  errors = {};

  countries = [];

  hasAttemptedSend = false;

  captcha = false;

  constructor() {
    makeObservable(this, {
      item: observable,
      invitation: observable,
      countries: observable,
      dialCodes: computed,
      errors: observable,
      hasAttemptedSend: observable,
      captcha: observable,
      load: action,
      insert: action,
      updateField: action,
      validate: action,
      setCaptcha: action,
    });
  }

  get dialCodes() {
    return [...this.countries].sort((a, b) =>
      a.dialCode > b.dialCode ? 1 : -1
    );
  }

  load({ params }) {
    this.currentParams = params;
    this.isLoading = true;
    const { id, projectId } = params;
    let promises = [
      agent.Countries.list(),
      agent.InvitationPerson.get(projectId, id),
    ];
    Promise.all(promises)
      .then(([countries, invitation]) => {
        runInAction(() => {
          this.countries = countries.data;
          this.invitation = invitation.data;
          if (invitation.data.requireFuture) {
            this.item.futureEvents = true;
          }
          this.isLoading = false;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.setGlobalMessage(
            "Error getting data from server: " + error,
            "error"
          );
          this.isLoading = false;
        });
      });
  }

  hasError(field) {
    return !(this.getError(field) === undefined);
  }

  getError(field) {
    if (!this.errors.details) {
      return undefined;
    }
    const err = this.errors.details.find((err) => err.path.includes(field));
    if (!err) {
      return undefined;
    }
    return err.message;
  }

  updateField(field, value) {
    this.item[field] = value;
    if (field == "companyCountryCode" 
      && this.item.mobile.length <= 5
      && value) {
      const el = this.countries.find((el) => el.countryCode == value);
      this.item.mobile = el.dialCode;
    }
    if (!this.errors.details) {
      return;
    }
    this.errors.details = this.errors.details.filter(
      (err) => !err.path.includes(field)
    );
    this.hideGlobalMessage();
  }

  validate() {
    if (!this.hasAttemptedSend) {
      return;
    }
    const validationResult = schema.validate(this.item, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        label: false,
      },
    });
    this.errors = validationResult.error || {};
    return this.errors.details === undefined;
  }

  setCaptcha(value) {
    this.captcha = value;
  }

  insert(cb) {
    this.hasAttemptedSend = true;
    if (!this.validate()) {
      this.setGlobalMessage(
        "One or more errors in the input. Correct the errors and try again.",
        "error"
      );
      return;
    }
    const { id, projectId } = this.currentParams;
    agent.InvitationPerson.insert(projectId, id, this.item)
      .then((response) => {
        if (!response) return;
        cb(projectId, id);
      })
      .catch((error) => {
        this.setGlobalMessage(
          "Could not insert person: " + error.response.data,
          "error"
        );
      });
  }
}

export default withIsLoading(withGlobalMessage(SignupModel));