import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import Slider from "@material-ui/core/Slider";
import Table from "../../components/table";
import Search from "../../components/search";
import Loader from "../../components/loader";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  mr1: {
    marginRight: theme.spacing(1),
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
}));

const marks = [
  {
    value: 0,
    label: "NO",
  },
  {
    value: 1,
    label: "A",
  },
  {
    value: 2,
    label: "B",
  },
  {
    value: 3,
    label: "C",
  },
];

const columns = (VM) => [
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "companyName", label: "Company" },
  {
    id: "tags",
    label: "Matching tags",
    computed: (val, elem) => {
      return (
        <React.Fragment>
          {elem.otherProjects.map((projectId) => {
            return (
              <Chip
                key={`chip_${projectId}_elem_${elem.id}`}
                variant="outlined"
                color="secondary"
                size="small"
                label={VM.projectDict[projectId].name}
              />
            );
          })}
          {elem.city && (
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label="city"
            />
          )}
          {elem.country && (
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label="country"
            />
          )}
          {elem.company && (
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label="company"
            />
          )}
          {elem.directSearch && (
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label="search"
            />
          )}
        </React.Fragment>
      );
    },
  },
  {
    id: "priority",
    label: "Priority",
    computed: (val, elem) => {
      return (
        <div style={{ paddingRight: "10px" }}>
          <Slider
            defaultValue={val}
            step={1}
            marks={marks}
            min={0}
            max={3}
            onChange={(evt, newValue) => VM.updatePriority(elem, newValue)}
          />
        </div>
      );
    },
  },
];

const EditProjectPeopleList = observer(({ VM, rows }) => {
  return (
    <Table stickyHeader={true} columns={columns(VM)} rows={rows} />
  );
});

const AddPeopleDialog = observer(({ VM }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (type) => {
    setAnchorEl(null);
    VM.openDialog(type);
  };

  const searchPeopleColumns = [
    {
      id: "id",
      label: "",
      type: "checkbox",
      selectedCount: VM.searchSelectedCount,
      count: VM.searchedPeople.length,
      selectDeselectAll: (val) => VM.selectDeselectAllSearch(val),
      selected: (row) => row.selectedInSearchPeople,
      handleChange: (item) => VM.changeSelectionSearch(item),
    },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "title", label: "Title" },
    { id: "companyName", label: "Company" },
    {
      id: "tags",
      label: "Tags",
      computed: (tags, elem) => {
        return tags.map((tag, index) => {
          return (
            <Chip
              key={`${elem.id}_tag_index_${index}`}
              variant="outlined"
              size="small"
              label={tag}
              color="primary"
            />
          );
        });
      },
    },
  ];

  return (
    <Grid item xs={12}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleMenuClick}
      >
        Add Customers to Gross List
      </Button>
      <Menu
        id="add-people-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose(-1)}
      >
        <MenuItem onClick={() => handleMenuClose(0)}>
          From another project
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose(1)}>From city</MenuItem>
        <MenuItem onClick={() => handleMenuClose(2)}>From country</MenuItem>
        <MenuItem onClick={() => handleMenuClose(3)}>From company</MenuItem>
        <MenuItem onClick={() => handleMenuClose(4)}>
          Search on name or tags
        </MenuItem>
      </Menu>
      <Dialog
        open={VM.dialogOpen}
        onClose={() => VM.closeDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Add People to Gross List
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Pick from the list to add people from that item.
          </DialogContentText>
          <FormControl fullWidth>
            <NativeSelect
              native="true"
              onChange={(evt) => VM.setDialogItem(evt.target.value)}
              inputProps={{
                name: "pick",
                id: "native-simple",
              }}
            >
              <option aria-label="None" value="" />
              {VM.dialogItems.map((item) => {
                return (
                  <option
                    key={`option_dialog_${item.value}`}
                    value={item.value}
                  >
                    {item.name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => VM.closeDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => VM.closeDialog(true)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={VM.searchDialogOpen}
        onClose={() => VM.closeSearchDialog(false)}
        aria-labelledby="form-dialog-title-search"
        maxWidth="lg"
        fullWidth={true}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="form-dialog-title-search">
          Add People to Gross List
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Search on people and mark them to add them to the project.
          </DialogContentText>
          <Grid item xs={12}>
            <Search
              value={VM.searchFilter}
              handleChange={(val) => VM.setSearchFiltered(val)}
            ></Search>
          </Grid>
          <Grid item xs={12} className={classes.mt2}>
            <Loader isLoading={VM.searchLoading}>
              <Table rows={VM.searchedPeople} columns={searchPeopleColumns}></Table>
            </Loader>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => VM.closeSearchDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => VM.closeSearchDialog(true)} color="primary">
            Add Selected
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
});

const EditProjectGross = observer(({ VM }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AddPeopleDialog VM={VM} />
      <Grid item xs={12} className={classes.mt2}>
        <Typography variant="h5" gutterBottom>
          International List
        </Typography>
        <EditProjectPeopleList VM={VM} rows={VM.world} />
      </Grid>
      <Grid item xs={12} className={classes.mt2}>
        <Typography variant="h5" gutterBottom>
          National List
        </Typography>
        <EditProjectPeopleList VM={VM} rows={VM.national} />
      </Grid>
      <Grid item xs={12} className={classes.mt2}>
        <Typography variant="h5" gutterBottom>
          Nordic List (excl. DK)
        </Typography>
        <EditProjectPeopleList VM={VM} rows={VM.nordic} />
      </Grid>
   </div>
  );
});

export default EditProjectGross;
