import {
    makeObservable,
    observable,
    action,
    computed,
    runInAction,
  } from "mobx";
  import { withForm } from "../../utils/vm";
  import agent from "../../agent";
  import Joi from "joi";
  
  const schema = Joi.object({
    name: Joi.string().required().max(100),
    tag: Joi.string().required().max(45),
    content: Joi.string().required(),
    sender: Joi.string().required().max(150).email({ tlds: { allow: false } }),
    title: Joi.string().required().max(150),
  });
  
  const emptyItem = { 
      name: '', 
      tag: '',
      content: '',
      sender: '',
      title: '',
    };
  
  class EditProjectMailsModel {
    items = [];
  
    editItem = Object.assign({}, emptyItem);
  
    dialogOpen = false;
  
    mainVM;
  
    constructor() {
      makeObservable(this, {
        items: observable,
        editItem: observable,
        dialogOpen: observable,
        setDialogOpen: action,
        setEditItem: action,
        updateOrInsert: action,
        delete: action,
      });
    }
  
    init(mainVM) {
      this.mainVM = mainVM;
      return agent.ProjectMails.list(mainVM.projectId())
        .then((response) => {
          if (!response) return;
          this.items = response.data;
        })
        .catch((error) => {
          this.mainVM.setGlobalMessage(
            "Could not get links for project: " + error.message,
            "error"
          );
        });
    }
  
    setDialogOpen(open) {
      this.dialogOpen = open;
      this.editItem = Object.assign({}, emptyItem);
    }
  
    setEditItem(item) {
      this.editItem = Object.assign({}, item);
      this.dialogOpen = true;
    }
  
    updateOrInsert() {
      if (!this.validate()) {
        this.mainVM.setGlobalMessage(
          "One or more errors in the input. Correct the errors and try again.",
          "error"
        );
        return;
      }
      const isUpdate = this.editItem.id;
      const promise = isUpdate
        ? agent.ProjectMails.update(
            this.mainVM.projectId(),
            this.editItem.id,
            this.editItem
          )
        : agent.ProjectMails.insert(
            this.mainVM.projectId(),
            this.editItem
          );
      promise
        .then((response) => {
          if (!response) return;
          runInAction(() => {
            if (isUpdate) {
              const index = this.items.findIndex(elem => elem.id == this.editItem.id);
              this.items = [
                ...this.items.slice(0, index),
                Object.assign({}, response.data),
                ...this.items.slice(index + 1),
              ];
            } else {
              this.items = [...this.items, response.data];
            }
            this.editItem = Object.assign({}, emptyItem);
            this.setDialogOpen(false);
          });
        })
        .catch((error) => {
          this.mainVM.setGlobalMessage(
            "Cound not insert or update the mail: " + error,
            "error"
          );
          this.setDialogOpen(false);
        });
    }
  
    delete(item) {
      agent.ProjectMails.delete(this.mainVM.projectId(), item.id)
        .then((response) => {
          if (!response) return;
          const index = this.items.indexOf(item);
          runInAction(() => {
            this.items = [
              ...this.items.slice(0, index),
              ...this.items.slice(index + 1),
            ];
          });
        })
        .catch((error) => {
          this.mainVM.setGlobalMessage(
            "Cound not delete the mail: " + error,
            "error"
          );
        });
    }

  }
  
  export default withForm(EditProjectMailsModel, schema, "editItem");
  