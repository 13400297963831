import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Table from "../../components/table";
import { HOST_NAME } from "../../utils/definitions";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
}));

const preventDefault = (event) => event.preventDefault();

const EditProjectGenericInvitation = observer(({ VM }) => {

  const classes = useStyles();

  const columns = [
    {
      id: "id",
      label: "",
      computed: (value, elem) => {
        return (
          <div>
            <IconButton aria-label="delete" onClick={() => VM.delete(elem)}>
              <DeleteIcon />
            </IconButton>
            <IconButton aria-label="edit" onClick={() => VM.setEditItem(elem)}>
              <EditIcon />
            </IconButton>
          </div>
        );
      },
    },
    { id: "name", label: "Name" },
    {
      id: "requireFuture",
      label: 'Require "Allow Future Invitations"',
      computed: (value) => {
        return <Checkbox checked={value} color="primary" disabled />;
      },
    },
    {
      id: "id",
      label: "Link",
      computed: (value) => {
        return (
          <Link onClick={preventDefault}>
            {`${HOST_NAME}/public/signup/${VM.projectId}/${value}`}
          </Link>
        );
      },
    },
  ];

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Table columns={columns} rows={VM.items}></Table>
      </Grid>
      <Grid item xs={12} className={classes.mt2}>
        <Button onClick={() => VM.setDialogOpen(true)} color="primary">
          New Open Invitation
        </Button>
      </Grid>
      <Dialog
        open={VM.dialogOpen}
        onClose={() => VM.setDialogOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {VM.editItem.id
            ? "Edit Open Invitation Link"
            : "New Open Invitation Link"}
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="Invitation Name"
              value={VM.editItem.name}
              onChange={(e) => VM.updateField("name", e.target.value)}
              fullWidth
              error={VM.hasError("name")}
              helperText={VM.getError("name")}
            />
          </Grid>
          <Grid item xs={12} className={classes.mt2}>
            <FormControlLabel
              control={
                <Switch
                  checked={VM.editItem.requireFuture}
                  onChange={(e) =>
                    VM.updateField("requireFuture", e.target.checked)
                  }
                  name="requireFuture"
                  color="primary"
                />
              }
              label={'Require accepting "Allow Future Invitations..."'}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => VM.setDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => VM.updateOrInsert()} color="primary">
            {VM.editItem.id ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default EditProjectGenericInvitation;
