import React from "react";
import { observer } from "mobx-react-lite";
import { lighten, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "../../components/table";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  mr1: {
    marginRight: theme.spacing(1),
  },
}));

const columns = (VM, type, selectedCount, count) => [
  {
    id: "id",
    label: "",
    type: "checkbox",
    selectedCount: selectedCount,
    count: count,
    selectDeselectAll: (val) => VM.selectDeselectAll(type, val),
    selected: (row) => row.selectedInPriorityTable,
    handleChange: (item) => VM.changeSelection(type, item),
  },
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "companyName", label: "Company" },
  {
    id: "priority",
    label: "Priority",
    computed: (val, elem) => {
      return (
        <React.Fragment>
          {elem.genericLink != "" && (
            <Chip
                key={`chip_genericLink_elem_${elem.id}`}
                variant="outlined"
                color="secondary"
                size="small"
                label={VM.projectPeopleModel.genericInvitationsDict[elem.genericLink].name}
              />
            )
          }
          {elem.priority == 1 && (
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label="A"
            />
          )}
          {elem.priority == 2 && (
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label="B"
            />
          )}
          {elem.priority == 3 && (
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label="C"
            />
          )}
        </React.Fragment>
      );
    },
  },
  {
    id: "customTags",
    label: "Tags",
    computed: (val, elem) => {
      return (
        <React.Fragment>
          {val.map((mailId) => {
            return (
              <Chip
                key={`chip_${mailId}`}
                variant="outlined"
                color="secondary"
                size="small"
                label={VM.mailDict[mailId].name}
              />
            );
          })}
        </React.Fragment>
      );
    },
  },
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = observer(({ title, selectedCount, handleRemoveClick, handleMailClick }) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: selectedCount > 0,
      })}
    >
      {selectedCount > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selectedCount} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {selectedCount > 0 && (
          <React.Fragment>
          <Tooltip title="Remove accepted">
            <IconButton aria-label="Remove" onClick={handleRemoveClick}>
              <RemoveCircleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Send Mail">
            <IconButton aria-label="Send" onClick={handleMailClick}>
              <MailIcon />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      )}
    </Toolbar>
  );
});

const EditProjectAcceptedWorld = observer(({ VM }) => {
  const myColumns = columns(
    VM,
    1,
    VM.worldSelectedCount,
    VM.world.length
  );
  return (
    <React.Fragment>
      <EnhancedTableToolbar
        title="International List"
        VM={VM}
        selectedCount={VM.worldSelectedCount}
        handleRemoveClick={() => VM.removeAcceptSelected(1)}
      />
      <Table
        stickyHeader={true}
        columns={myColumns}
        rows={VM.world}
      />
    </React.Fragment>
  );
});

const EditProjectAcceptedNational = observer(({ VM }) => {
  const myColumns = columns(
    VM,
    0,
    VM.nationalSelectedCount,
    VM.national.length
  );
  return (
    <React.Fragment>
      <EnhancedTableToolbar
        title="National List"
        VM={VM}
        selectedCount={VM.nationalSelectedCount}
        handleRemoveClick={() => VM.removeAcceptSelected(0)}
      />
      <Table
        stickyHeader={true}
        columns={myColumns}
        rows={VM.national}
      />
    </React.Fragment>
  );
});

const EditProjectAcceptedNordic = observer(({ VM }) => {
  const myColumns = columns(
    VM,
    2,
    VM.nordicSelectedCount,
    VM.nordic.length
  );
  return (
    <React.Fragment>
      <EnhancedTableToolbar
        title="Nordic List (excl. DK)"
        VM={VM}
        selectedCount={VM.nordicSelectedCount}
        handleRemoveClick={() => VM.removeAcceptSelected(2)}
      />
      <Table
        stickyHeader={true}
        columns={myColumns}
        rows={VM.nordic}
      />
    </React.Fragment>
  );
});

const EditProjectAccepted = observer(({ VM }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid item xs={12} className={classes.mt2}>
        <EditProjectAcceptedWorld VM={VM} />
      </Grid>
      <Grid item xs={12} className={classes.mt2}>
        <EditProjectAcceptedNational VM={VM} />
      </Grid>
      <Grid item xs={12} className={classes.mt2}>
        <EditProjectAcceptedNordic VM={VM} />
      </Grid>
    </div>
  );
});

export default EditProjectAccepted;
