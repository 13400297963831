import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}));

const EditProjectLinks = observer(({ VM }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1">
          Links
      </Typography>
      <Grid item xs={12}>
      <FormControl fullWidth>
          <InputLabel htmlFor="new_link">Add New Link</InputLabel>
          <Input
            id="new_link"
            type="text"
            value={VM.newLink}
            onChange={(e) => VM.updateNewLink(e.target.value)}
            fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="add link"
                    onClick={() => VM.insert()}
                  >
                      <AddCircleIcon />
                  </IconButton>
                </InputAdornment>
              }
              />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <List>
          {VM.items.map((item) => {
            return (
              <ListItem key={`link_key_${item.id}`}>
                <ListItemText primary={
                    <Link href={item.link} target="_blank" rel="noreferrer">
                        {item.link}
                    </Link>
                  } />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => VM.delete(item)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </div>
  );
});

export default EditProjectLinks;
