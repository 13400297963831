import React from "react";
import { makeObservable, action, observable, runInAction } from "mobx";

import agent from "../agent";

class GlobalStore {
    
    title = "...";
    user = {}
    data = {}

    constructor() {
        makeObservable(this, {
            title: observable,
            user: observable,
            data: observable,
            setTitle: action,
            load: action,
        });
    }

    setTitle(newTitle) {
        this.title = newTitle;
    }

    load() {
      agent.Users.getCurrentUser()
        .then(response => {
          if (!response) return;
          runInAction(() => {
            this.user = response.data;
          })
        })
    }
}


const GlobalContext = React.createContext();
 
export const GlobalProvider = ({ children }) => {
  const store = new GlobalStore();
  store.load();
  return (
    <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>
  );
};
 
/* Hook to use store in any functional component */
export const useGlobal = () => React.useContext(GlobalContext);
 
/* HOC to inject store to any functional or class component */
export const withGlobal = (Component) => (props) => {
  return <Component {...props} store={useGlobal()} />;
};
