import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import Grid from "@material-ui/core/Grid";

import { PieChart } from "../../components/charts";
import Loader from "../../components/loader";
import Typography from "@material-ui/core/Typography";

import { useGlobal } from "../../stores/global";
import { withViewModel } from "../../utils/vm";
import { objectKeysToString, parseDate, format } from "../../utils/helpers";
import Table from "../../components/table";
import Model from "./dashboard_model";

const PeopleByYear = observer(({ VM }) => {
  const data = toJS(VM.data);
  return (
    <Loader isLoading={VM.isLoading}>
      <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        People by Year
      </Typography>
        <PieChart
          style={{ width: "100%", height: "400px" }}
          data={data}
        ></PieChart>
      </Grid>
    </Loader>
  );
});

const PeopleByCountry = observer(({ VM }) => {
  return (
    <Loader isLoading={VM.isLoading}>
      <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        People by Country
      </Typography>
        <PieChart
          style={{ width: "100%", height: "400px" }}
          data={toJS(VM.data)}
        ></PieChart>
      </Grid>
    </Loader>
  );
});

const columns = [
  { id: "name", label: "Name" },
  { id: "start", label: "Start", computed: (date) => format(date) },
  { id: "end", label: "End", computed: (date) => format(date) },
];

const ActiveProjects = observer(({ VM }) => {
  const navigate = useNavigate();
  return (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        Active Projects
      </Typography>
      <Table
        rows={VM.items}
        columns={columns}
        handleRowClick={(element) =>
          navigate(`/backend/projects/edit/${element.id}`)
        }
      ></Table>
    </Grid>
  );
});

const DashBoard = observer(({ VM }) => {
  const globalStore = useGlobal();
  globalStore.setTitle("Dashboard");

  return (
    <React.Fragment>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <PeopleByYear VM={VM.peopleYear} />
          <PeopleByCountry VM={VM.peopleCountry} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ActiveProjects VM={VM.activeProjects} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default withViewModel(new Model())(DashBoard);
