import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';

const MyDropzone = ({ filesHandler }) => {
  const onDrop = useCallback(filesHandler, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box bgcolor="text.secondary" color="background.paper" p={2}  textAlign="center">
        <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
            <p>Drop the files here ...</p>
        ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
        )}
        </div>
    </Box>      
  );
};

export default MyDropzone;
