import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
  get,
} from "mobx";

import agent from "../../agent";

import { withIsLoading, withGlobalMessage } from "../../utils/vm";

class ConfirmModel {
  item = { firstName: "...", lastName: "", accepted: 0, projectName: "..." };

  constructor() {
    makeObservable(this, {
      item: observable,
      name: computed,
      update: action,
    });
  }

  get name() {
    return `${this.item.firstName} ${this.item.lastName}`;
  }

  load({ params }) {
    this.currentParams = params;
    this.isLoading = true;
    const { id, projectId } = params;
    agent.ConfirmPerson.get(projectId, id)
      .then(({ data }) => {
        runInAction(() => {
          this.item = data;
          this.isLoading = false;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.setGlobalMessage(
            "Error getting data from server: " + error,
            "error"
          );
          this.isLoading = false;
        });
      });
  }

  update(accepted) {
    const { id, projectId } = this.currentParams;
    const newAccepted = parseInt(accepted);
    agent.ConfirmPerson.update(projectId, id, { accepted: newAccepted })
        .then(response => {
            runInAction(() => {
                this.item.accepted = newAccepted;
            });
        })
        .catch((error) => {
            runInAction(() => {
              this.setGlobalMessage(
                "Error updating going: " + error,
                "error"
              );
              this.isLoading = false;
            });
          });
  }
}

export default withIsLoading(withGlobalMessage(ConfirmModel));