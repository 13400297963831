import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";

import agent from "../../agent";

class EditProjectAcceptedModel {
  
  projectPeopleModel = {};

  nordicSelected = {};

  worldSelected = {};

  nationalSelected = {};

  constructor(projectPeopleModel) {
    this.projectPeopleModel = projectPeopleModel;
    makeObservable(this, {
      projectPeopleModel: observable,
      nordicSelected: observable,
      worldSelected: observable,
      nationalSelected: observable,
      selectDeselectAll: action,
      nordic: computed,
      world: computed,
      national: computed,
      nordicSelectedCount: computed,
      worldSelectedCount: computed,
      nationalSelectedCount: computed,
    });
  }

  get world() {
    return this.projectPeopleModel.worldOriginal.filter(
      (elem) => (elem.priority > 0 || elem.genericLink != "") && elem.accepted > 0
    );
  }

  get nordic() {
    return this.projectPeopleModel.nordicOriginal.filter(
      (elem) => (elem.priority > 0 || elem.genericLink != "") && elem.accepted > 0
    );
  }

  get national() {
    return this.projectPeopleModel.nationalOriginal.filter(
      (elem) => (elem.priority > 0 || elem.genericLink != "") && elem.accepted > 0
    );
  }

  getSelectedMap(type) {
    if (type == 0) {
      return this.nationalSelected;
    } else if (type == 1) {
      return this.worldSelected;
    } else if (type == 2) {
      return this.nordicSelected;
    }
    throw `Type ${type} not found`;
  }

  selectDeselectAll(type, value) {
    if (type == 0) {
      this.nationalSelected = {};
    } else if (type == 1) {
      this.worldSelected = {};
    } else if (type == 2) {
      this.nordicSelected = {};
    }
    const selectedMap = this.getSelectedMap(type);
    var applyToAll = (elem) => {
      elem.selectedInPriorityTable = value;
      if (value) {
        selectedMap[elem.id] = elem;
      }
    };
    if (type == 0) {
      this.national.forEach(applyToAll);
    } else if (type == 1) {
      this.world.forEach(applyToAll);
    } else if (type == 2) {
      this.nordic.forEach(applyToAll);
    }
  }

  getPrioritySelected(type) {
    if (type == 0) {
      return this.nationalSelected;
    } else if (type == 1) {
      return this.worldSelected;
    } else if (type == 2) {
      return this.nordicSelected;
    }
    throw `Type ${type} not found`;
  }

  changeSelection(type, item) {
    var map = this.getPrioritySelected(type);
    if (item.selectedInPriorityTable) {
      delete map[item.id];
      item.selectedInPriorityTable = false;
    } else {
      map[item.id] = item;
      item.selectedInPriorityTable = true;
    }
  }

  removeAcceptSelected(type) {
    var ids = [];
    if (type == 0) {
      ids = Object.keys(this.nationalSelected);
      this.nationalSelected = {};
    } else if (type == 1) {
      ids = Object.keys(this.worldSelected);
      this.worldSelected = {};
    } else {
      ids = Object.keys(this.nordicSelected);
      this.nordicSelected = {};
    }
    agent.ProjectPeople.unaccept(this.projectPeopleModel.mainVM.projectId(), {
      ids: ids,
    })
    .then((response) => {
      if (!response) return;
      return this.projectPeopleModel.fetchPersonData();
    })
    .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not set remove accepted for the selected people: " + error.message,
          "error"
        );
      });
  }

  get worldSelectedCount() {
    return Object.keys(this.worldSelected).length;
  }

  get nationalSelectedCount() {
    return Object.keys(this.nationalSelected).length;
  }

  get nordicSelectedCount() {
    return Object.keys(this.nordicSelected).length;
  }
}

export default EditProjectAcceptedModel;