import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Table from "../../components/table";

import Editor from "../../components/tinymce";

import { useTemplates } from "../../stores/templates";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
}));

const preventDefault = (event) => event.preventDefault();

const EditProjectMails = observer(({ VM }) => {
  const columns = [
    {
      id: "id",
      label: "",
      computed: (value, elem) => {
        return (
          <div>
            <IconButton aria-label="delete" onClick={() => VM.delete(elem)}>
              <DeleteIcon />
            </IconButton>
            <IconButton aria-label="edit" onClick={() => VM.setEditItem(elem)}>
              <EditIcon />
            </IconButton>
          </div>
        );
      },
    },
    { id: "name", label: "Name" },
    { id: "tag", label: "Tag" },
    { id: "sender", label: "Sender" },
    { id: "title", label: "Title" },
  ];

  const classes = useStyles();
  const templates = useTemplates();
  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Table columns={columns} rows={VM.items}></Table>
      </Grid>
      <Grid item xs={12} className={classes.mt2}>
        <Button onClick={() => VM.setDialogOpen(true)} color="primary">
          New Mail
        </Button>
      </Grid>
      <Dialog
        open={VM.dialogOpen}
        onClose={() => VM.setDialogOpen(false)}
        maxWidth="md"
        fullWidth={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {VM.editItem.id ? "Edit Mail" : "New Mail"}
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} className={classes.mt2}>
            <TextField
              id="name"
              label="Name"
              value={VM.editItem.name}
              onChange={(e) => VM.updateField("name", e.target.value)}
              fullWidth
              error={VM.hasError("name")}
              helperText={VM.getError("name")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="tag"
              label="Tag"
              value={VM.editItem.tag}
              onChange={(e) => VM.updateField("tag", e.target.value)}
              fullWidth
              error={VM.hasError("tag")}
              helperText={VM.getError("tag")}
            />
          </Grid>
          <Grid item xs={12} className={classes.mt2}>
            <TextField
              id="sender"
              label="Sender"
              value={VM.editItem.sender}
              onChange={(e) => VM.updateField("sender", e.target.value)}
              fullWidth
              error={VM.hasError("sender")}
              helperText={VM.getError("sender")}
            />
          </Grid>
          <Grid item xs={12} className={classes.mt2}>
            <TextField
              id="title"
              label="Title"
              value={VM.editItem.title}
              onChange={(e) => VM.updateField("title", e.target.value)}
              fullWidth
              error={VM.hasError("title")}
              helperText={VM.getError("title")}
            />
          </Grid>
          <Grid item xs={12} className={classes.mt2}>
            <Editor
              initialValue={VM.editItem.content}
              templateVariables={templates.templateVariables}
              onChange={(content) => {
                VM.updateField("content", content);
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => VM.setDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => VM.updateOrInsert()} color="primary">
            {VM.editItem.id ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default EditProjectMails;
