import {
  makeObservable,
  observable,
  action,
  runInAction,
} from "mobx";
import { withForm } from "../../utils/vm";
import Joi from "joi";

import agent from "../../agent";

const schema = Joi.object({
  name: Joi.string().required(),
  start: Joi.date().required(),
  end: Joi.date().required(),
});

class EditProjectDataModel {
  item = {
    name: "",
    start: new Date(),
    end: new Date(),
  };

  params;

  mainVM;

  constructor() {
    makeObservable(this, {
      item: observable,
      init: action,
      update: action,
    });
  }

  init(mainVM, params) {
    this.mainVM = mainVM;
    this.params = params;
    return agent.Projects.get(params.id)
      .then((response) => {
        if (!response) return;
        runInAction(() => {
          this.item = response.data;
        });
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not get information about project: " + error.message,
          "error"
        );
      });
  }

  update() {
    if (!this.validate()) {
      this.mainVM.setGlobalMessage(
        "One or more errors in the input. Correct the errors and try again.",
        "error"
      );
      return;
    }
    const { id } = this.params;
    agent.Projects.update(id, this.item)
      .then((response) => {
        if (!response) return;
        this.mainVM.setGlobalMessage(
          `${this.item.name} was updated successfully`
        );
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not update person: " + error.message,
          "error"
        );
      });
  }
}

export default withForm(EditProjectDataModel, schema);
