import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";
import { withForm } from "../../utils/vm";
import agent from "../../agent";
import Joi from "joi";

const schema = Joi.object({
  name: Joi.string().required(),
});

const emptyItem = { name: "", requireFuture: false };

class EditProjectGenericInvitationModel {

  items = [];

  editItem = Object.assign({}, emptyItem);

  dialogOpen = false;

  mainVM;

  constructor() {
    makeObservable(this, {
      items: observable,
      editItem: observable,
      dialogOpen: observable,
      projectId: computed,
      setDialogOpen: action,
      setEditItem: action,
      updateOrInsert: action,
      delete: action,
    });
  }

  get projectId() {
    return this.mainVM.projectId();
  }

  init(mainVM) {
    this.mainVM = mainVM;
    return agent.ProjectGenericInvitation.list(mainVM.projectId())
      .then((invitations) => {
        if (!invitations) return;
        this.items = invitations.data;
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not get links for project: " + error.message,
          "error"
        );
      });
  }

  setDialogOpen(open) {
    this.dialogOpen = open;
    this.editItem = Object.assign({}, emptyItem);
  }

  setEditItem(item) {
    this.editItem = Object.assign({}, item);
    this.dialogOpen = true;
  }

  updateOrInsert() {
    if (!this.validate()) {
      this.mainVM.setGlobalMessage(
        "One or more errors in the input. Correct the errors and try again.",
        "error"
      );
      return;
    }
    const isUpdate = this.editItem.id;
    const promise = isUpdate
      ? agent.ProjectGenericInvitation.update(
          this.mainVM.projectId(),
          this.editItem.id,
          this.editItem
        )
      : agent.ProjectGenericInvitation.insert(
          this.mainVM.projectId(),
          this.editItem
        );
    promise
      .then((response) => {
        if (!response) return;
        runInAction(() => {
          if (isUpdate) {
            const item = this.items.find((item) => item.id == this.editItem.id);
            item = Object.assign({}, this.editItem);
          } else {
            this.items = [...this.items, response.data];
          }
          this.editItem = Object.assign({}, emptyItem);
          this.setDialogOpen(false);
        });
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Cound not insert or update the generic invitation: " + error,
          "error"
        );
        this.setDialogOpen(false);
      });
  }

  delete(item) {
    agent.ProjectGenericInvitation.delete(this.mainVM.projectId(), item.id)
      .then((response) => {
        if (!response) return;
        const index = this.items.indexOf(item);
        runInAction(() => {
          this.items = [
            ...this.items.slice(0, index),
            ...this.items.slice(index + 1),
          ];
        });
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Cound not delete the generic invitation: " + error,
          "error"
        );
      });
  }
}

export default withForm(EditProjectGenericInvitationModel, schema, "editItem");
