import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Panel, { PanelNoLoader } from "../../components/panel";
import Snackbar from "../../components/snackbar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";

import { withViewModel } from "../../utils/vm";

// VMs
import EditProjectModel from "./edit_project_model";
import EditProjectDataModel from "./edit_project_data_model";
import EditProjectLinksModel from "./edit_project_links_model";
import EditProjectFilesModel from "./edit_project_files_model";
import EditProjectPeopleModel from "./edit_project_people_model";
import EditProjectGenericInvitationModel from "./edit_project_generic_invitation_model";
import EditProjectMailsModel from "./edit_project_mails_model";
import EditProjectAcceptedModel from "./edit_project_accepted_model";

// Views
import EditProjectData from "./edit_project_data";
import EditProjectLinks from "./edit_project_links";
import EditProjectFiles from "./edit_project_files";
import EditProjectGenericInvitation from "./edit_project_generic_invitation";
import EditProjectMails from "./edit_project_mails";
import EditProjectGross from "./edit_project_gross";
import EditProjectNet from "./edit_project_net";
import EditProjectAccepted from "./edit_project_accepted";

const editProjectDataModel = new EditProjectDataModel();
const EditProjectDataView =
  withViewModel(editProjectDataModel)(EditProjectData);
const editProjectLinksModel = new EditProjectLinksModel();
const EditProjectLinksView = withViewModel(editProjectLinksModel)(
  EditProjectLinks
);

const editProjectFilesModel = new EditProjectFilesModel();
const EditProjectFilesView = withViewModel(editProjectFilesModel)(
  EditProjectFiles
);

const editProjectGenericInvitationModel =
  new EditProjectGenericInvitationModel();
const EditProjectGenericInvitationView = withViewModel(
  editProjectGenericInvitationModel
)(EditProjectGenericInvitation);

const editProjectMailsModel = new EditProjectMailsModel();
const EditProjectMailsView = withViewModel(editProjectMailsModel)(
  EditProjectMails
);

const editProjectPeopleModel = new EditProjectPeopleModel();
const EditProjectGrossView = withViewModel(editProjectPeopleModel)(
  EditProjectGross
);
const EditProjectNetView = withViewModel(editProjectPeopleModel)(
  EditProjectNet
);

const editProjectAcceptedModel = new EditProjectAcceptedModel(
  editProjectPeopleModel
);
const EditProjectAcceptedView = withViewModel(editProjectAcceptedModel)(
  EditProjectAccepted
);

const VMs = [
  editProjectDataModel,
  editProjectLinksModel,
  editProjectFilesModel,
  editProjectPeopleModel,
  editProjectGenericInvitationModel,
  editProjectMailsModel,
];

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  buttons: {
    "& > *": {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  },
}));

const TabPanel = ({ children, value, index, ...rest }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...rest}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const DownloadGenerated = ({VM}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, classes.buttons)}>
      <Button color="primary" onClick={() => {
        window.open(`/api/projects/${VM.projectId()}/generated?template=comma`, '_blank');
      }}>
        Download as comma separated
      </Button>
      <Button color="primary" onClick={() => {
        window.open(`/api/projects/${VM.projectId()}/generated?template=column`, '_blank');
      }}>
        Download as column separated
      </Button>
    </div>
  );
};

const EditProject = observer(({ VM }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Panel isLoading={VM.isLoading}>
        <Tabs
          value={VM.tabIndex}
          onChange={(evt, newValue) => VM.setTabIndex(newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Project Settings"></Tab>
          <Tab label="Files and Links"></Tab>
          <Tab label="Open Invitations"></Tab>
          <Tab label="Mails"></Tab>
        </Tabs>
        <TabPanel value={VM.tabIndex} index={0}>
          <EditProjectDataView />
        </TabPanel>
        <TabPanel value={VM.tabIndex} index={1}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={6}>
              <EditProjectFilesView />
            </Grid>
            <Grid item xs={6}>
              <EditProjectLinksView />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={VM.tabIndex} index={2}>
          <EditProjectGenericInvitationView />
        </TabPanel>
        <TabPanel value={VM.tabIndex} index={3}>
          <EditProjectMailsView />
        </TabPanel>
      </Panel>
      {!VM.isLoading && (
        <PanelNoLoader>
          <Stepper nonLinear activeStep={VM.peopleStepIndex}>
            <Step>
              <StepButton
                onClick={(step) => VM.setPeopleStepIndex(0)}
                completed={false}
              >
                Add and prioritize customers
              </StepButton>
            </Step>
            <Step>
              <StepButton
                onClick={(step) => VM.setPeopleStepIndex(1)}
                completed={false}
              >
                Not confirmed customers
              </StepButton>
            </Step>
            <Step>
              <StepButton
                onClick={(step) => VM.setPeopleStepIndex(2)}
                completed={false}
              >
                Confirmed customers
              </StepButton>
            </Step>
          </Stepper>
          {VM.peopleStepIndex == 0 && <EditProjectGrossView />}
          {VM.peopleStepIndex == 1 && <EditProjectNetView />}
          {VM.peopleStepIndex == 2 && <EditProjectAcceptedView />}
          <DownloadGenerated VM={VM} />
        </PanelNoLoader>
      )}
      <Snackbar
        handleClose={() => VM.hideGlobalMessage()}
        {...VM.globalMessage}
      />
    </React.Fragment>
  );
});

export default withViewModel(new EditProjectModel(VMs))(EditProject);
