import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { useGlobal } from "../../stores/global";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}));

const EditProjectData = observer(({ VM }) => {
  useGlobal().setTitle(`Edit ${VM.item.name}`);

  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid item xs={12}>
        <TextField
          id="name"
          label="Project name"
          value={VM.item.name}
          onChange={(e) => VM.updateField("name", e.target.value)}
          onBlur={(e) => VM.update()}
          fullWidth
          error={VM.hasError("name")}
          helperText={VM.getError("name")}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-start"
          label="Start Date"
          format="MM/dd/yyyy"
          value={VM.item.start}
          onChange={(date) => {
              VM.updateField("start", date);
              VM.update();
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          error={VM.hasError("start")}
          helperText={VM.getError("start")}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-end"
          label="End Date"
          format="MM/dd/yyyy"
          value={VM.item.end}
          onChange={(date) => {
            VM.updateField("end", date);
            VM.update();
        }}
        KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          error={VM.hasError("end")}
          helperText={VM.getError("end")}
        />
      </Grid>
    </form>
  );
});

export default EditProjectData;
