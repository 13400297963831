import { useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";

const dateFns = new DateFnsUtils();

export function objectKeysToString(element, separator) {
  return Object.values(element).join(separator).toLowerCase();
}

export function parseDate(dateAsString) {
  return dateFns.date(dateAsString);
}

export function format(date) {
  return dateFns.format(date, "yyyy-MM-dd");
}

export function isDateDefined(date) {
  if (!(date instanceof Date)) {
    date = parseDate(date);
  }
  return dateFns.isAfter(date);
}

export function checkPassword(password) {
  var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  return re.test(password);
}

export const appendScript = (scriptToAppend, cb) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  if (cb) {
    script.addEventListener('load', cb, false);
  }
  document.body.appendChild(script);
}