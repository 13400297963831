import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MySnackbar({severity = "info", open, message, duration=4000, handleClose, ...remProps}) {

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={handleClose} {...remProps}>
    <Alert onClose={handleClose} severity={severity}>
        {message}
    </Alert>
    </Snackbar>
  );
}
