import React from "react";
import { appendScript } from '../utils/helpers';

const ChartContext = React.createContext();

class ChartLoader {

    scriptLoading = false;
    waitingCallbacks = [];
    loadPromise = null;

    loadChart = (cb, data) => {
        if (this.scriptLoading) {
            this.waitingCallbacks.push(cb);
            return;
        }
        if (this.loadPromise) {
            this.handleCallback(cb);
            return;
        }
        this.scriptLoading = true;
        appendScript("https://www.gstatic.com/charts/loader.js", () => {
            this.loadPromise = window.google.charts.load('current', {packages: ['corechart']});
            this.scriptLoading = false;
            this.waitingCallbacks.forEach(this.handleCallback)
        });
    }

    handleCallback = cb => {
        this.loadPromise = this.loadPromise.then(() => cb(window.google));
    }
}


/* Hook to use store in any functional component */
export const useChartLoader = () => React.useContext(ChartContext);

export const ChartProvider = ({ children }) => {
    return <ChartContext.Provider value={new ChartLoader()}>{children}</ChartContext.Provider>
}