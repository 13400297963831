import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";

import agent from "../../agent";
import { isDateDefined } from "../../utils/helpers";

class EditProjectPeopleModel {
  nordicOriginal = [];

  worldOriginal = [];

  nationalOriginal = [];

  nordicPrioritySelected = {};

  worldPrioritySelected = {};

  nationalPrioritySelected = {};

  projects = [];

  projectDict = {};

  cities = [];

  countries = [];

  companies = [];

  people = [];

  dialogType = -1;

  dialogItems = [];

  dialogSelectedItem = {};

  mainVM;

  updatingPriority = {};

  searchDialogOpen = false;

  searchFilter = "";

  searchedPeople = [];

  searchLoading = false;

  searchWaiting = false;

  searchSelectedCount = 0;

  genericInvitationsDict = {};

  constructor() {
    makeObservable(this, {
      nordicOriginal: observable,
      nordic: computed,
      nordicPriority: computed,
      nordicPrioritySelected: observable,
      nordicPrioritySelectedCount: computed,
      worldOriginal: observable,
      world: computed,
      worldPriority: computed,
      worldPrioritySelected: observable,
      worldPrioritySelectedCount: computed,
      nationalOriginal: observable,
      national: computed,
      nationalPriority: computed,
      nationalPrioritySelected: observable,
      nationalPrioritySelectedCount: computed,
      projects: observable,
      cities: observable,
      countries: observable,
      people: observable,
      dialogOpen: computed,
      dialogType: observable,
      dialogItems: observable,
      dialogSelectedItem: observable,
      init: action,
      openDialog: action,
      closeDialog: action,
      setDialogItem: action,
      updatePriority: action,
      changeSelection: action,
      selectDeselectAll: action,
      searchDialogOpen: observable,
      searchFilter: observable,
      searchLoading: observable,
      searchedPeople: observable,
      searchSelectedCount: observable,
      setSearchFiltered: action,
      closeSearchDialog: action,
      selectDeselectAllSearch: action,
      changeSelectionSearch: action,
      acceptSelected: action,
      genericInvitationsDict: observable,
    });
  }

  get nordic() {
    return this.nordicOriginal.filter((elem) => elem.genericLink == "");
  }

  get nordicPriority() {
    return this.nordicOriginal.filter(
      (elem) =>
        (elem.priority > 0 || elem.genericLink != "") && elem.accepted <= 0
    );
  }

  get world() {
    return this.worldOriginal.filter((elem) => elem.genericLink == "");
  }

  get worldPriority() {
    return this.worldOriginal.filter(
      (elem) =>
        (elem.priority > 0 || elem.genericLink != "") && elem.accepted <= 0
    );
  }

  get national() {
    return this.nationalOriginal.filter((elem) => elem.genericLink == "");
  }

  get nationalPriority() {
    return this.nationalOriginal.filter(
      (elem) =>
        (elem.priority > 0 || elem.genericLink != "") && elem.accepted <= 0
    );
  }

  getPrioritySelected(type) {
    if (type == 0) {
      return this.nationalPrioritySelected;
    } else if (type == 1) {
      return this.worldPrioritySelected;
    } else if (type == 2) {
      return this.nordicPrioritySelected;
    }
    throw `Type ${type} not found`;
  }

  changeSelection(type, item) {
    var map = this.getPrioritySelected(type);
    if (item.selectedInPriorityTable) {
      delete map[item.id];
      item.selectedInPriorityTable = false;
    } else {
      map[item.id] = item;
      item.selectedInPriorityTable = true;
    }
  }

  selectDeselectAll(type, value) {
    if (type == 0) {
      this.nationalPrioritySelected = {};
    } else if (type == 1) {
      this.worldPrioritySelected = {};
    } else {
      this.nordicPrioritySelected = {};
    }
    const prioritySelected = this.getPrioritySelected(type);
    var applyToAll = (elem) => {
      elem.selectedInPriorityTable = value;
      if (value) {
        prioritySelected[elem.id] = elem;
      }
    };
    if (type == 0) {
      this.nationalPriority.forEach(applyToAll);
    } else if (type == 1) {
      this.worldPriority.forEach(applyToAll);
    } else {
      this.nordicPriority.forEach(applyToAll);
    }
  }

  get nordicPrioritySelectedCount() {
    return Object.keys(this.nordicPrioritySelected).length;
  }

  get worldPrioritySelectedCount() {
    return Object.keys(this.worldPrioritySelected).length;
  }

  get nationalPrioritySelectedCount() {
    return Object.keys(this.nationalPrioritySelected).length;
  }

  init(mainVM) {
    this.mainVM = mainVM;
    return Promise.all([this.fetchOtherData(), this.fetchPersonData()]);
  }

  fetchOtherData() {
    const projectsPromise = agent.Projects.list();
    const companyInfoPromise = agent.IndustryPerson.companyInfo();
    const countryInfoPromise = agent.Countries.list();
    const genericInvitations = agent.ProjectGenericInvitation.list(this.mainVM.projectId());
    return Promise.all([
      projectsPromise,
      companyInfoPromise,
      countryInfoPromise,
      genericInvitations,
    ])
      .then(([projects, companyInfo, countries, genericInvitations]) => {
        runInAction(() => {
          this.projects = projects.data;
          this.projectDict = projects.data.reduce((acc, elem) => {
            acc[elem.id] = elem;
            return acc;
          }, {});
          this.cities = companyInfo.data.cities;
          this.companies = companyInfo.data.companies;
          this.countries = countries.data;
          this.genericInvitationsDict = genericInvitations.data.reduce((acc, elem) => {
            acc[elem.id] = elem;
            return acc;
          }, {});
        });
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not get information about people in project: " + error.message,
          "error"
        );
      });
  }

  fetchPersonData() {
    return agent.ProjectPeople.list(this.mainVM.projectId())
      .then((response) => {
        if (!response) return;
        runInAction(() => {
          var mapped = response.data.map((elem) => {
            if (elem.otherProjects.length == 0) {
              elem.otherProjects = [];
            } else {
              elem.otherProjects = elem.otherProjects.split(",");
            }
            elem.selectedInPriorityTable = false;
            return elem;
          });
          // If we ever add one more region, please unify this.
          this.nordicOriginal = mapped.filter((person) => person.region == 2);
          this.worldOriginal = mapped.filter((person) => person.region == 1);
          this.nationalOriginal = mapped.filter((person) => person.region == 0);
        });
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not get information about people in project: " + error.message,
          "error"
        );
      });
  }

  openDialog(type) {
    if (type < 0) {
      return;
    }
    switch (type) {
      case 0:
        const projectId = this.mainVM.projectId();
        this.dialogItems = this.projects
          .filter((project) => project.id != projectId)
          .map((project) => ({
            value: project.id,
            name: project.name,
          }));
        break;
      case 1:
        this.dialogItems = this.cities.map((city) => ({
          value: city,
          name: city,
        }));
        break;
      case 2:
        this.dialogItems = this.countries.map((country) => ({
          value: country.countryCode,
          name: country.name,
        }));
        break;
      case 3:
        this.dialogItems = this.companies.map((company) => ({
          value: company,
          name: company,
        }));
        break;
      case 4:
        this.searchedPeople = [];
        this.searchDialogOpen = true;
        this.searchLoading = false;
        return;
    }
    this.dialogType = type;
  }

  get dialogOpen() {
    return this.dialogType > -1;
  }

  closeDialog(applyUpdates) {
    if (applyUpdates) {
      agent.ProjectPeople.addPersons(this.mainVM.projectId(), {
        type: this.dialogType,
        value: this.dialogSelectedItem,
      })
        .then((response) => {
          if (!response) return;
          return this.fetchPersonData();
        })
        .then(() => {
          runInAction(() => {
            this.dialogType = -1;
          });
        })
        .catch((error) => {
          this.mainVM.setGlobalMessage(
            "Could not get information about people in project: " +
              error.message,
            "error"
          );
        });
    } else {
      this.dialogType = -1;
    }
  }

  setDialogItem(selectedValue) {
    this.dialogSelectedItem = selectedValue;
  }

  updatePriority(elem, val) {
    if (this.updatingPriority[elem.id]) {
      return;
    }
    this.updatingPriority[elem.id] = true;
    agent.ProjectPeople.updatePriority(this.mainVM.projectId(), elem.id, {
      priority: val,
    })
      .then((response) => {
        if (!response) return;
        runInAction(() => {
          elem.priority = val;
          delete this.updatingPriority[elem.id];
        });
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not get set priority for person: " + error.message,
          "error"
        );
      });
  }

  setSearchFiltered(value, force) {
    this.searchFilter = value;
    if ((value.length > 2 && !this.searchLoading) || force) {
      this.searchLoading = true;
      this.searchWaiting = false;
      agent.IndustryPerson.search(this.searchFilter).then((people) => {
        if (this.searchWaiting) {
          this.setSearchFiltered(this.searchFilter, true);
          return;
        }
        runInAction(() => {
          this.searchLoading = false;
          this.searchSelectedCount = 0;
          this.searchedPeople = people.data.map((item) =>
            Object.assign(item, { selectedInSearchPeople: false })
          );
        });
      });
    } else {
      this.searchWaiting = true;
    }
  }

  closeSearchDialog(add) {
    if (!add) {
      this.searchDialogOpen = false;
      return;
    }
    agent.ProjectPeople.addPersons(this.mainVM.projectId(), {
      type: 4,
      value: this.searchedPeople
        .filter((item) => item.selectedInSearchPeople)
        .map((item) => item.id)
        .join(),
    })
      .then((response) => {
        if (!response) return;
        return this.fetchPersonData();
      })
      .then(() => {
        runInAction(() => {
          this.searchDialogOpen = false;
        });
      })
      .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not get information about people in project: " + error.message,
          "error"
        );
      });
  }

  selectDeselectAllSearch(val) {
    this.searchedPeople = this.searchedPeople.map((item) =>
      Object.assign(item, { selectedInSearchPeople: val })
    );
    this.searchSelectedCount = val ? this.searchedPeople.length : false;
  }

  changeSelectionSearch(item) {
    if (item.selectedInSearchPeople) {
      item.selectedInSearchPeople = false;
      this.searchSelectedCount -= 1;
    } else {
      item.selectedInSearchPeople = true;
      this.searchSelectedCount += 1;
    }
  }

  acceptSelected(type) {
    var ids = [];
    if (type == 0) {
      ids = Object.keys(this.nationalPrioritySelected);
      this.nationalPrioritySelected = {};
    } else if (type == 1) {
      ids = Object.keys(this.worldPrioritySelected);
      this.worldPrioritySelected = {};
    } else {
      ids = Object.keys(this.nordicPrioritySelected);
      this.nordicPrioritySelected = {};
    }
    agent.ProjectPeople.accept(this.mainVM.projectId(), {
      ids: ids,
    })
    .then((response) => {
      if (!response) return;
      return this.fetchPersonData();
    })
    .catch((error) => {
        this.mainVM.setGlobalMessage(
          "Could not set accepted for the selected people: " + error.message,
          "error"
        );
      });
  }
}

export default EditProjectPeopleModel;
