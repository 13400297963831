import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Panel from "../../components/panel";

import { useGlobal } from "../../stores/global";
import { withViewModel } from "../../utils/vm";

import Model from "./signup_complete_model";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  signup: {
    margin: theme.spacing(2),
  },
}));

const Created = () => {
  return <Panel>FINISHED</Panel>;
};

const Person = observer(({ VM }) => {
  useGlobal().setTitle(`Signup for '${VM.invitation.name}'`);

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Panel isLoading={VM.isLoading}>
        <Grid className={classes.signup}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body2">
                We have registered your application and we thank you for your interest.
            </Typography>
          </Grid>
        </Grid>
        </Grid>
    </Panel>
  );
});

const View = observer(({ VM }) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const globalStore = useGlobal();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {globalStore.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            {VM.showCreated ? <Created VM={VM} /> : <Person VM={VM} />}
          </Grid>
        </Container>
      </main>
    </div>
  );
});

export default withViewModel(new Model())(View);
