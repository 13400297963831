import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Loader from "./loader"

const useStyles = makeStyles((theme) => ({
  panel: {
    padding: theme.spacing(1),
  },
}));


export function PanelNoLoader(props) {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Paper>
        <div className={classes.panel}>
          {props.children}
        </div>
      </Paper>
    </Grid>
  );
}

export default function Panel(props) {
  const classes = useStyles();
  const { isLoading } = props;
  return (
    <Loader isLoading={isLoading}>
      <Grid item xs={12}>
        <Paper>
          <div className={classes.panel}>
            {props.children}
          </div>
        </Paper>
      </Grid>
    </Loader>
  );
}
