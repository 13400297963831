import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { GlobalProvider } from "./stores/global";
import { ChartProvider } from "./stores/chart";
import { TemplateProvider } from "./stores/templates"

import DateFnsUtils from "@date-io/date-fns";

import Login from "./login";
import Backend from "./backend/app";
import Public from "./public/routes"

const Home = () => (
  <div>
    <h2>FOOOOO</h2>
  </div>
);

function App() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <GlobalProvider>
        <TemplateProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="login" element={<Login />} />
              <Route path="backend/*" element={<ChartProvider><Backend /></ChartProvider>} />
              <Route path="public/*" element={<Public />} />
            </Routes>
          </Router>
        </TemplateProvider>
      </GlobalProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
