import React from "react";

import Grid from "@material-ui/core/Grid";

const Loader = ({ children, isLoading }) => {
  if (isLoading) {
    return (
      <Grid item xs={12}>
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
      </Grid>
    );
  } else {
    return children;
  }
};

export default Loader;
