import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { he } from "date-fns/locale";

const MyEditor = ({ initialValue, value, onChange, height = 300, templateVariables = [] }) => {

    return (
        <Editor
            initialValue={initialValue}
            value={value}
            apiKey="3sluxuxh20n8jn38xyzv99edm6g28ty3vjtoe5b51tmoa242"
            init={{
                height: height,
                plugins: ['link', 'image', 'preview'],
                branding: false,
                toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | variables",
                setup: editor => {
                    editor.ui.registry.addMenuButton('variables', {
                        text: 'Variables',
                        fetch: function (callback) {
                            callback(templateVariables.map(variable => {
                                return Object.assign({}, variable, {
                                    type: 'menuitem',
                                    text: `${variable.name} - ${variable.description}`,
                                    onAction: function () {
                                        editor.insertContent(variable.name)
                                    }
                                });
                            }))
                        }
                    })
                }
            }}
            onEditorChange={onChange}
        />
    )
}

export default MyEditor;