import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";
import Joi from "joi";

import { withGlobalMessage, withIsLoading, withForm } from "../../utils/vm";

import agent from "../../agent";

const schema = Joi.object({
  tag: Joi.string().max(45),
  content: Joi.string().required(),
  sender: Joi.string()
    .required()
    .max(150)
    .email({ tlds: { allow: false } }),
  title: Joi.string().required().max(150),
});

class ProjectSendMailModel {

  selectedTemplateId = "";

  sending = false;

  sendSuccessMessage = 'Sent emails to all 2 recipients';
  sendFailureMessage = 'Sending emails failed for3 recipients:\n\nMorten Peter - morten@dotnamics.com\nmorten Anders - peter@dotnamics.com\n';
  sendResult = 0;

  items = [];

  people = [];

  selectedIds = [];

  projectId = '';

  currentTemplate = { 
    tag: "",
    sender: '',
    name: '',
    title: '',
    content: '',
    persistentMailId: '',
    id: '',
  };

  constructor() {
    makeObservable(this, {
      currentTemplate: observable,
      selectedTemplateId: observable,
      selectedIds: observable,
      people: observable,
      items: observable,
      projectId: observable,
      sending: observable,
      sendSuccessMessage: observable,
      sendFailureMessage: observable,
      sendResult: observable,
      setContentTemplate: action,
      load: action,
      changeSelection: action,
      selectedCount: computed,
      send: action,
    });
  }

  load({ params, globalStore }) {
    this.isLoading = true;
    const { projectMailPersonIds = {} } = globalStore.data;
    this.selectedIds = projectMailPersonIds.selectedIds;
    this.people = (projectMailPersonIds.people || [])
      .filter((p) => this.selectedIds.hasOwnProperty(p.id))
      .map((p) => Object.assign({}, p, { selected: true }));
    const { id } = params;
    this.projectId = id;
    return agent.ProjectMails.list(id)
      .then((response) => {
        if (!response) return;
        runInAction(() => {
          const defaultItem =  {
            id: "defaultItem",
            persistentMailId: "",
            name: "Pick a template or write a message below",
            content: "",
            sender: "",
            title: "",
            tag: "",
          }
          this.items = [defaultItem, ...response.data];
          this.setContentTemplate("defaultItem");
          this.isLoading = false;
        });
      })
      .catch((error) => {
        this.setGlobalMessage(
          "Could not get links for project: " + error.message,
          "error"
        );
      });
  }

  setContentTemplate(templateId) {
    this.selectedTemplateId = templateId;
    this.currentTemplate = this.items.find(
      (element) => element.id == templateId
    );
  }

  changeSelection(item) {
    item.selected = !item.selected;
    if (item.selected) {
        this.selectedIds[item.id] = item;
    } else {
        delete this.selectedIds[item.id];
    }
  }

  selectDeselectAll(selected) {
    this.selectedIds = {};
    if (selected) {
        this.people.forEach(p => {
            p.selected = true;
            this.selectedIds[p.id] = p;
        });
    } else {
        this.people.forEach(p => p.selected = selected);
    }
  }

  get selectedCount() {
      if (!this.selectedIds) {
          return 0;
      }
    return Object.keys(this.selectedIds).length;
  }

  send() {
    const personIds = Object.keys(this.selectedIds);
    this.sending = true;
    agent.ProjectPeopleMail.sendMail(this.projectId, { 
      tag: this.currentTemplate.tag,
      sender: this.currentTemplate.sender,
      title: this.currentTemplate.title,
      content: this.currentTemplate.content,
      projectPersistentMailId: this.currentTemplate.persistentMailId,
      projectMailId: this.currentTemplate.id,
      projectPersonIds: personIds,
    }).then((response) => {
      runInAction(() => {
        const { successes, failures } = response.data;
        if (failures.length > 0) {
          const failuresInfo = failures.map((id, errorMessage) => {
            var person = this.selectedIds[id];
            return `${person.firstName} ${person.lastName} (${person.email}) - ${errorMessage}\n`;
          })
          this.sendFailureMessage = `Sending emails failed for ${failures.length} recipients:\n\n${failuresInfo}`;
          this.sendResult = 2;
        } else {
          this.sendSuccessMessage = `Sent emails to all ${successes.length} recipients`;
          this.sendResult = 1; 
        }
      })
    }).catch((error) => {
      this.setGlobalMessage(
        "Could not send mails for project: " + error.message,
        "error"
      );
    });
  }
}

export const buildPayload = (globalStore, VM, selectedIds, people) => {
  globalStore.data = Object.assign(globalStore.data, {
    projectMailPersonIds: {
      selectedIds: selectedIds,
      people: people,
    },
  });
};

export default withGlobalMessage(
  withIsLoading(withForm(ProjectSendMailModel, schema, "currentTemplate"))
);
