import React from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';

const MySearch = ({value, handleChange}) => {
  return (<Grid item xs={12}>
    <Paper>
      <FormControl variant="outlined" fullWidth autoComplete="off">
        <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>
        <OutlinedInput
          id="outlined-adornment-search"
          value={value}
          onChange={evt => handleChange(evt.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="search"
                onClick={() => handleChange(value)}
                edge="end"
              >
                <Search />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>
    </Paper>
  </Grid>);
};

export default MySearch;
