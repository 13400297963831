import React from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route } from "react-router-dom";

import Signup from "./signup/signup";
import SignupComplete from "./signup/signup_complete"
import Confirm from "./confirm/confirm"

const PublicRoutes = observer(() => {
  return (
    <div>
      <Routes>
        <Route path="signup/:projectId/:id" element={<Signup />}></Route>
        <Route path="signup_complete/:projectId/:id" element={<SignupComplete />}></Route>
        <Route path="confirm/:projectId/:id" element={<Confirm />}></Route>
      </Routes>
    </div>
  );
});

export default PublicRoutes;
