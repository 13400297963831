import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Panel from "../../components/panel";
import Snackbar from "../../components/snackbar";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { useGlobal } from "../../stores/global";
import { withViewModel } from "../../utils/vm";
import NewProjectModel from "./new_project_model";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  buttons: {
    "& > *": {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  },
}));

const NewProject = observer(({ VM }) => {
  useGlobal().setTitle("Add New Project");

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Panel isLoading={VM.isLoading}>
      <form className={classes.root} noValidate autoComplete="off">
        <Grid item xs={12}>
          <TextField
            id="name"
            label="Project name"
            value={VM.item.name}
            onChange={(e) => VM.updateField("name", e.target.value)}
            fullWidth
            error={VM.hasError("name")}
            helperText={VM.getError("name")}
          />
        </Grid>
        <Grid item xs={12}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-start"
            label="Start Date"
            format="MM/dd/yyyy"
            value={VM.item.start}
            onChange={(date) => VM.updateField("start", date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            error={VM.hasError("start")}
            helperText={VM.getError("start")}
          />
        </Grid>
        <Grid item xs={12}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-end"
            label="End Date"
            format="MM/dd/yyyy"
            value={VM.item.end}
            onChange={(date) => VM.updateField("end", date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            error={VM.hasError("end")}
            helperText={VM.getError("end")}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                VM.insert(id => {
                  navigate(`/backend/projects/edit/${id}?new=true`);
                })
              }}
            >
              Add Project
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                VM.clear();
                navigate("/backend/projects");
              }}
            >
              Back
            </Button>
          </div>
        </Grid>
      </form>
      <Snackbar
        handleClose={() => VM.hideGlobalMessage()}
        {...VM.globalMessage}
      />
    </Panel>
  );
});

export default withViewModel(new NewProjectModel())(NewProject);
