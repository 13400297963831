import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
  get,
} from "mobx";
import Joi from "joi";

import agent from "../../agent";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  mobile: Joi.string()
    .allow("")
    .pattern(/^\+[0-9\-]+$/),
});

const tagSchema = Joi.object({
  tag: Joi.string().required()
});

const getEmptyItem = () => {
  return {
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    dial_code: "",
    mobile: "",
    companyName: "",
    companyWebsite: "",
    companyCity: "",
    companyCountryCode: "",
    futureEvents: false,
    untrusted: false,
    tags: [],
  };
};

export default class PersonModel {
  item = getEmptyItem();

  errors = {};

  globalMessage = {
    open: false,
    message: "",
    severity: "info",
  };

  countries = [];

  currentParams = {};

  isLoading = true;

  tagDialogOpen = false;

  newTag = "";

  newTagError = false;

  existing = {}

  deleteDialogOpen = false;

  constructor() {
    makeObservable(this, {
      item: observable,
      countries: observable,
      errors: observable,
      globalMessage: observable,
      currentParams: observable,
      isLoading: observable,
      tagDialogOpen: observable,
      newTag: observable,
      newTagError: observable,
      existing: observable,
      deleteDialogOpen: observable,
      isNew: computed,
      name: computed,
      hasExisting: computed,
      load: action,
      insert: action,
      updateField: action,
      validate: action,
      clear: action,
      hideGlobalMessage: action,
      setGlobalMessage: action,
      openTagDialog: action,
      deleteTag: action,
      closeTagDialog: action,
      setTag: action,
      checkExisting: action,
      clearExisting: action,
      loadId: action,
      closeDeleteDialog: action,
      openDeleteDialog: action,
    });
  }

  get isNew() {
    const { id } = this.currentParams;
    return id == "add";
  }

  get name() {
    return this.item.firstName + " " + this.item.lastName;
  }

  load({ params }) {
    this.clear();
    this.currentParams = params;
    this.isLoading = true;
    const { id } = params;
    const countriesQuery = agent.Countries.list();
    let promises =
      id != "add"
        ? [countriesQuery, agent.IndustryPerson.get(id)]
        : [countriesQuery];
    Promise.all(promises)
      .then(([countries, person]) => {
        runInAction(() => {
          this.countries = countries.data;
          if (person) {
            this.item = person.data;
          }
          this.isLoading = false;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.setGlobalMessage(
            "Error getting data from server: " + error,
            "error"
          );
          this.isLoading = false;
        });
      });
  }

  hasError(field) {
    return !(this.getError(field) === undefined);
  }

  getError(field) {
    if (!this.errors.details) {
      return undefined;
    }
    const err = this.errors.details.find((err) => err.path.includes(field));
    if (!err) {
      return undefined;
    }
    return err.message;
  }

  updateField(field, value) {
    this.item[field] = value;
    this.checkExisting();
    if (!this.errors.details) {
      return;
    }
    this.errors.details = this.errors.details.filter(
      (err) => !err.path.includes(field)
    );
    this.hideGlobalMessage();
  }

  validate() {
    const validationResult = schema.validate(this.item, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        label: false,
      },
    });
    this.errors = validationResult.error || {};
    return this.errors.details === undefined;
  }

  clear() {
    this.item = getEmptyItem();
    this.errors = {};
    this.hideGlobalMessage();
    this.existing = {};
  }

  setGlobalMessage(message, severity, callback) {
    this.globalMessage = {
      open: true,
      message,
      severity,
      callback,
    };
  }

  hideGlobalMessage() {
    this.globalMessage.open = false;
    if (this.globalMessage.callback) {
      this.globalMessage.callback();
    }
    this.globalMessage.callback = undefined;
  }

  insert(cb) {
    if (!this.validate()) {
      this.setGlobalMessage(
        "One or more errors in the input. Correct the errors and try again.",
        "error"
      );
      return;
    }
    agent.IndustryPerson.insert(this.item)
      .then((response) => {
        if (!response) return;
        cb(response.data.id);
        this.currentParams = { id: response.data.id };
      })
      .catch((error) => {
        this.setGlobalMessage(
          "Could not insert person: " + error.message,
          "error"
        );
      });
  }

  update(cb) {
    if (!this.validate()) {
      this.setGlobalMessage(
        "One or more errors in the input. Correct the errors and try again.",
        "error"
      );
      return;
    }
    const { id } = this.currentParams;
    agent.IndustryPerson.update(id, this.item)
      .then((response) => {
        if (!response) return;
        cb();
      })
      .catch((error) => {
        this.setGlobalMessage(
          "Could not update person: " + error.message,
          "error"
        );
      });
  }

  setTag = (newValue) => {
    this.newTag = newValue;
    this.newTagError = false;
  }

  openTagDialog = () => {
    this.newTag = "";
    this.newTagError = false;
    this.tagDialogOpen = true;
  }

  closeTagDialog = (update) => {
    if (!update) {
      this.tagDialogOpen = false;
      return;
    }
    const validationResult = tagSchema.validate({
      tag: this.newTag
    }, {
      errors: {
        label: false,
      },
    });
    const errors = validationResult.error;
    this.newTagError = errors !== undefined;
    if (this.newTagError) {
      return;
    }
    const newTags = this.newTag.split(',').map(item => item.trim()).filter(item => item.length > 0);
    this.item.tags = [...this.item.tags, ...newTags]
    this.tagDialogOpen = false;
  }

  deleteTag = (tag) => {
    const original = this.item.tags;
    const index = original.indexOf(tag);
    if (index >= 0) {
      this.item.tags = [...original.slice(0, index), ...original.slice(index + 1)];
    }
  }

  checkExisting = () => {
    if (!this.isNew) {
      return;
    }
    if (this.item.firstName === "" || this.item.lastName === "") {
      return;
    }
    agent.IndustryPerson.existing({
      firstName: this.item.firstName,
      lastName: this.item.lastName,
      email: this.item.email,
      mobile: this.item.mobile
    }).then((response) => {
      if (!response) return;
      runInAction(() => {
        if (response.data) {
          this.existing = response.data;
        } else {
          this.existing = {}
        }
      })
    })
    .catch((error) => {
      this.setGlobalMessage(
        "Could not execute search for existing: " + error.message,
        "error"
      );
    });
  }

  get hasExisting() {
    return this.existing.id !== undefined;
  }

  clearExisting() {
    this.existing = {}
  }

  loadId = (id) => {
    this.load({params: {id: id}})
  }

  openDeleteDialog = () => {
    this.deleteDialogOpen = true;
  }

  closeDeleteDialog = (deleteUser, cb) => {
    this.deleteDialogOpen = false;
    if (deleteUser) {
      agent.IndustryPerson.delete(this.item.id)
      .then(() => {
        cb();
      })
    }
  }
}
